.block-style {
    display: inline-block;
}
.ctv-container {
	--player-icon-size: 200px;
	--controls-icon-size: 40px;
	--controls-pri-color: white;
	--controls-sec-color: black;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.ctv-container .payment-details-ctv {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.ctv-container .more-section .item:focus,
.ctv-container .SettingScreen a:focus {
	border-radius:7px;
	transform:scale(1.04);
	cursor:pointer;
	background-color:#acacaa;
}

@media (max-width:1400px) {
    .ctv-container li.settingScale:focus {border-radius:4px;}
}

/* .ctv-container #standard-grid.grid-view .swiper-container .thumbLink:focus {
	transform: scale(1.07);
}

.ctv-container #standard-grid.grid-view .swiper-container .thumbLink:focus + .titleHolder p {
	transform: scale(1.07);
	text-shadow: 0 3px 20px rgba(0,0,0, .4);
}

.ctv-container .grid-view .thumbLink:focus {transform:scale(1.07); -webkit-transform:scale(1.07); -moz-transform:scale(1.07); -ms-transform:scale(1.07); -o-transform:scale(1.07); -webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden; backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.1); 
}

.ctv-container .logInScreen input:focus, .ctv-container .searchContent .thumbLink:focus {-webkit-transform:scale(1.07); -ms-transform:scale(1.07); transform:scale(1.07);} */

.ctv-container .jumbo-view .swiper-slide:focus, .ctv-container .jumbo-view .slideHover{transform:scale(1.06); -webkit-transform:scale(1.06); -moz-transform:scale(1.06); -ms-transform:scale(1.06); -o-transform:scale(1.06); -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden; -ms-backface-visibility: hidden;backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.06);}



/* .ctv-container .swiper-slide:focus {
	outline: 2px solid red;
}

.ctv-container :focus {
	outline: 2px solid red;
} */


/* .ctv-container .swiper-slide:focus {
	outline: 2px solid red;
} */

/* .ctv-container a:focus {
	outline: 2px solid red;
} */

.ctv-container .with-modal #nav-bar {
	position: relative;
	/* position: fixed;
	top: 0; */
	width: 100%;
}
.ctv-container #nav-bar {
	display: flex;
	align-items: center;
	justify-content: center;
}

.simulator-route-container #nav-bar > div {
	margin: 0 20px;
}

.ctv-container .wh-100 {
	width: 100%;
	height: 100%;
}

.ctv-container .hg-black {
	background: #525252 !important;
}

.ctv-container .hg-black:active {
	background: #fff !important;
}

.ctv-container .video-inlineMenu .menu{
	padding: 30px;
	margin-top: 30px;
	background: #f3f1f1;
	/* width: 100%; */
	/* width: max-content; */
	/* min-width: 100%; */
	min-width: max-content;
	height: 315px;
	transition: height 450ms;
    box-sizing: border-box;
}
.ctv-container #fullscreen * * .detail-swiper > .thumbLink:focus + div.titleHolder{
    display:block;
}


.ctv-container #standard-grid.grid-view .swiper-container .thumbLink:focus {
	transform: scale(1.07);
}
.ctv-container #standard-grid.grid-view .swiper-container .thumbLink:focus + .titleHolder p {
	transform: scale(1.07);
	text-shadow: 0 3px 20px rgba(0,0,0, .4);
}

.ctv-container .grid-view .thumbLink:focus, .grid-view .thumbLink.slideHover{transform:scale(1.07); -webkit-transform:scale(1.07); -moz-transform:scale(1.07); -ms-transform:scale(1.07); -o-transform:scale(1.07); -webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden; backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.1); }

.ctv-container .logInScreen input:hover, 
.ctv-container .logInScreen input.btnHover, 
.ctv-container .searchContent .thumbLink:focus, 
.ctv-container .searchContent .thumbLink.slideHover{-webkit-transform:scale(1.07); -ms-transform:scale(1.07); transform:scale(1.07);}

.ctv-container a.thumbLink.thumbHover:focus .img-video .img-thumb, 
.ctv-container a.thumbLink.hoverElmnt .img-video .img-thumb{opacity:1;}
.ctv-container a.thumbLink.thumbHover:focus .img-video .video-thumb, 
.ctv-container a.thumbLink.hoverElmnt .img-video .video-thumb, 
.ctv-container .img-thumb, 
.ctv-container .SettingScreen li, 
.ctv-container .detail .summaryHolder, 
.ctv-container .search-row, 
.ctv-container .showFeVideo, 
.ctv-container .showVideo, 
.ctv-container .showImage, 
.ctv-container .showGallery, 
.ctv-container .searchContent .thumbLink, 
.ctv-container .grid-view .thumbLink, 
.ctv-container img.bye, 
.ctv-container .titleHolder span, 
.ctv-container .saveIcon span{display:block;}

.ctv-container a.thumbLink.thumbHover, .ctv-container a.thumbLink { display: block;}
.ctv-container .video-inlineMenu .menu.not-visible {
    padding: 0;
    margin: 0;
    height: 0;
}

.ctv-container .successImgIcon {
	background: url("../images/success.png");
	background-size: contain;
	width: 200px;
	height: 200px;
}

.ctv-container .failureImgIcon {
	background: url("../images/failure.png");
	background-size: contain;
	width: 200px;
	height: 200px;
}

.ctv-container .fix-nav > .nav-view + div {
	margin-top: 0;
}

.ctv-container div:not(.fix-nav) > #nav-bar + div .user-access {
	margin-top: 0;
}
.ctv-container #standard-grid.grid-view .titleHolder a {
	align-self: flex-start;
}
.ctv-container .search-section .search-row {
	/* display: flex;
	flex-wrap: wrap; */
	padding-right: 0;
	padding-left: 0;
}
.ctv-container .search-section .search-row .searchContent {
	/* flex-basis: 31.2% !important; */
	width: 92% !important;
	/* margin: 1.5%; */
}
.ctv-container .search-section .search-row .Cell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
.ctv-container .data-privacy .privacy-item {
	display: flex;
    align-items: center;
    justify-content: start;
}
.ctv-container .data-privacy .privacy-item p {
	width: calc(100% - 450px);
}
.ctv-container .data-privacy .privacy-item a {
	width: 225px;
}
.ctv-container #myframe {
	height: 100vh;
}
.ctv-container #nav-bar + div .subscribeScreen-wrapper {
	padding: 0;
}

.ctv-container #nav-bar + div .subscribeScreen-wrapper .subscription-cntnt {
	min-height: calc(100vh - 270px);
}
.ctv-container .video-inlineMenu .menu .swiper-container .swiper-slide {
	padding-bottom: 0;
}
.ctv-container .detail-view-cls .detailContent {
	position: fixed;
}
.ctv-container .detail-view-cls .detail-view .swiper-container.swiper-container-vertical {
	padding-top: 30px;
}
.ctv-container .detail-view-cls > div > h2 {
	position: fixed;
}
.ctv-container .detail-view-cls .detail-view .swiper-slide.swiper-slide-active {
	transform: scale(1);
	transform: perspective(1px) scale(1)
}

.ctv-container .detail-view-cls .detail-view .swiper-slide.focusedEle {
	transform: scale(1.06);
	transform: perspective(1px) scale(1.06)
}

.ctv-container #detail-view-content .swiper-wrapper {
	transform: translate3d(0,0,0) !important;
}
.ctv-container .loader-scrn{
	position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* background: rgba(0,0,0,0.97); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
	align-items: center;
	background: #efefef;
	z-index: 10;
}
.ctv-container .loader-scrn img.logoHolder, 
.ctv-container .payment-details-ctv img.logoHolder {
	max-height: 60px;
}
.ctv-container .loader-scrn > .logoHolder {
	margin-top: -250px !important;
}

.ctv-container .userLoginCntnt .loader-scrn > .logoHolder {
	margin-top: -3em !important;
}

.ctv-container .loader-scrn .loader-details {
	font-size: 25px;
	color: black;
	background: white;
	padding: 40px 80px;
	width: 80%;
	margin-left: auto;
    margin-right: auto;
}

.ctv-container .loader-scrn .loader-details b {
	font-size: 30px;
	margin-left: 30px;
	word-break: break-all;
	display: inline-block;
}

.ctv-container .payment-details-ctv .loader-details {
	min-width: 900px;
	font-size: 22px;
	margin-top: 40px;
}

.ctv-container .payment-details-ctv .loader-details b {
	margin-left: 0;
	display: block;
}

.ctv-container .payment-details-ctv .loader-details > div {
	margin: 5px 0;
}

.ctv-container .fb-loader-details {
	background: white;
    color: black;
	width: 1000px;
	position: relative;
}

.ctv-container .fb-loader-details span {
	position: absolute;
    top: 5px;
    left: 5px;
}

.ctv-container .fb-loader-details span img {
	width: 40px;
}

.ctv-container .fb-loader-details .fb-code {
    text-align: center;
    padding: 50px 0;
    background: lightgray;
    color: #3b5998;
    font-size: 72px;
}

.ctv-container .fb-loader-details .fb-desc {
    padding: 65px 90px;
    box-sizing: border-box;
    text-align: center;
}

.ctv-container .fb-desc b {
	font-size: 32px;
}

.ctv-container .bckgrnds .videoBg > div {
	width: 100%;
    height: 100%;
}

.ctv-container .bckgrnds .videoBg > div video {
	min-height: 100vh;
}

.ctv-container #fullscreen-grid .activeInside + span h2,
.ctv-container #fullscreen-grid .activeInside + span .ctaHolder {
	display: none;
}

.ctv-container .SettingScreen a {
	width: 100%;
}

.ctv-container iframe {
	outline: none !important;
	border: none !important;
}

.ctv-container .d-block {
	display: block;
}

.ctv-container #standard-grid {
	position: relative;
}

.ctv-container .swiper-button-next, .ctv-container .swiper-button-prev {
	height: 200px !important;
	top: 37%;
}

.ctv-container .swiper-button-next:focus, .ctv-container .swiper-button-prev:focus {
	opacity: 1;
}
.ctv-container .interstitial + #nav-with-modal-id > #nav-bar {
	display: none !important;
}

.ctv-container .interstitial .detailContent {
	padding-top: 100px;
}

.ctv-container .content-box.jumbo .slider-box.jumbo-view {
	position: relative;
}

.ctv-container .content-box .jumbo-view .swiper-container,
.ctv-container #standard-grid .swiper-container,
.ctv-container #spotlight-grid .swiper-container.swiper-container-horizontal,
.ctv-container #fullscreen-grid .swiper-container.swiper-container-horizontal {
	width: calc(100% - 50px);
	margin: 0 25px;
}

.ctv-container #spotlight-grid .spotlight.grid-view .swiper-button-next,
.ctv-container #spotlight-grid .spotlight.grid-view .swiper-button-prev,
.ctv-container #fullscreen-grid .spotlight.grid-view .swiper-button-next,
.ctv-container #fullscreen-grid .spotlight.grid-view .swiper-button-prev {
	padding: 0;
	top: 30%;
    height: 150px !important;
	width: 130px !important;
}

.ctv-container .swiper-button-prev {
	left: 5px;
}
.ctv-container .swiper-button-next {
	right: 5px;
}

.ctv-container .content-box .jumbo-view .swiper-button-prev, .ctv-container .content-box .jumbo-view .swiper-button-next,
.ctv-container #standard-grid .swiper-button-prev, .ctv-container #standard-grid .swiper-button-next {
	width: 130px !important;
}

.ctv-container .btn-fbIcon:focus, 
.ctv-container .btn-email:focus, 
.ctv-container .btnBox a:focus, 
.ctv-container .btnBox button:focus, 
.ctv-container .btnForgot a:focus, 
.ctv-container .btn-cta:focus, 
.ctv-container .reContent a:focus{
	transform: scale(1.1);
}
.ctv-container .btnBox a:focus, 
.ctv-container .btnForgot a:focus, 
.ctv-container .btnBox button:focus {
	color: #000 !important;
}
.ctv-container a.btn-try:focus, 
.ctv-container a.btn-cancel:focus, 
.ctv-container .logInScreen input:focus, 
.ctv-container .btnBox a:focus, 
.ctv-container .btnBox button:focus, 
.ctv-container .btnForgot a:focus {
	background-color: #fff;
}
.ctv-container .logInScreen input:focus{
	transform: scale(1.07);
}
.ctv-container  a.btn-trial:focus, 
.ctv-container  .play a:focus{
	transform: scale(1.06);
}

.samsung-container img.img-thumb {
	filter: brightness(0.9);
}

#geofence-modal button:focus{
	transform: scale(1.15);
}



.ctv-container .d-flex,
.ctv-container .slick-track {
	display: flex;
}
.ctv-container .slick-vertical > .slick-list > .slick-track {
	display: block;
}

.ctv-container .saved-items-wrapper .slick-track{
	display: flex;
	flex-wrap: wrap;
	row-gap: 50px;
  	transform: translate3d(0px, 0, 0) !important;
	width: 100vw !important;
}

.ctv-container .saved-items-wrapper #standard-grid.ml .slick-track{
	margin-left: 2.5vw;
}

.ctv-container .saved-jumboitems-wrapper .slick-track{
	display: flex;
	flex-wrap: wrap;
    transform: translate3d(0px, 0, 0) !important;
	width: 100vw !important;
}
.ctv-container .saved-items-wrapper #standard-grid.grid-view  .slick-slider{
	padding: 22px 0;
}

.ctv-container .slick-slider .slick-slide {
	padding: 0 20px;
}
.ctv-container .remove-transition, .ctv-container .grid-view .thumbLink:focus {
	transition: none !important;
	transform: none !important;
}

.ctv-container #standard-grid.grid-view .slick-slider {
	padding: 22px 2em 0;
	margin-bottom: 20px;
}

.ctv-container #standard-grid.grid-view .slick-slider .slick-list {
	margin-right: 2em;
}

.ctv-container #standard-grid .slick-slider,
.ctv-container #spotlight-grid .slick-slider {
	width: calc(100% - 50px);
	margin: 0 25px;
	box-sizing: border-box;
}

#spotlight-grid .spotlight.grid-view > .slick-slider {
	padding-top: 15px;
}

.ctv-container .EPG-layout .channel .channel-programes .show .show-title-wrapper {
	/* margin-right: -5px;
	margin-bottom: -4px !important; */
}

.ctv-container .EPG-layout .channel .channel-programes .channel-name {
	/* margin-bottom: 2px;
	margin-top: 2px; */
}

.ctv-container .focusable:focus, .onboarding-screen .focusable:focus {
	/* border: 2px solid yellow !important; */
	box-shadow: 0 0 0 2px yellow !important;
	/* box-shadow: 0px 2px 5px 7px #afafaf; */
}
.ctv-container a:focus {
	outline: none;
}

.ctv-container .userLoginCntnt .btnForgot a{
	outline: none;
}

.ctv-container .exit-popup button{
	outline: none;
}

.ctv-container .thumbLink.focusable:focus {
	/* border: none !important; */
	box-shadow: none !important;
}
.ctv-container .thumbLink.focusable:focus > div {
	/* border: 2px solid yellow !important; */
	box-shadow: 0 0 0 2px yellow !important;
}

.ctv-container .banner .focusable:focus {
	/* border: none !important; */
	box-shadow: none !important;
}

.ctv-container .banner .focusable:focus > img{
	/* border: 2px solid yellow !important; */
	box-shadow: 0 0 0 2px yellow !important;
}

.ctv-container .fwd-icon {
    position: fixed;
    top: calc(50% - var(--player-icon-size)/2);
    left: calc(57% - var(--player-icon-size)/2);
}


.ctv-container .rwd-icon {
	position: fixed;
    top: calc(50% - var(--player-icon-size)/2);
    left: calc(43% - var(--player-icon-size)/2);
}

.ctv-container .play-icon, .ctv-container .pause-icon, .ctv-container .stop-icon {
	position: fixed;
    top: calc(50% - var(--player-icon-size)/2);
    left: calc(50% - var(--player-icon-size)/2);
}

.ctv-container .fwd-icon svg, .ctv-container .rwd-icon svg, .ctv-container .play-icon svg, .ctv-container .pause-icon svg, .ctv-container .stop-icon svg {
	height: var(--player-icon-size);
	width: var(--player-icon-size);
	fill: white;
}

.ctv-container video::-webkit-media-controls { /* Works only on Chrome-based browsers */
    display: none;
}

.ctv-container #custom-seekbar
{  
	cursor: pointer;
    height: 10px;
    border: thin solid var(--controls-pri-color);
    overflow: hidden;
    position: relative;
    width: calc(100vw - 400px);
	border-radius: 5px;
}
.ctv-container #custom-seekbar span.seek
{
  background-color: var(--controls-pri-color);
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 0px;
  border-radius: 5px;
}

.ctv-container .custom-controls {
	width: 100vw;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    background-color: rgba(0,0,0,0.5);
    padding: 0 30px;
    box-sizing: border-box;
	padding-bottom: 10px;
}

.ctv-container .theo.custom-controls {
	padding-bottom: 0;
}

.ctv-container .progress-bar {
	width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ctv-container .progress-bar > span {
	width: 130px;
}

.ctv-container .controls-buttons-div {
	width: 100%;
	display: flex;
    align-items: center;
    justify-content: space-between;

}

.ctv-container .custom-controls .text-right {
	text-align: right;
}

.controls-buttons-div .play-pause {
	height: var(--controls-icon-size);
}
.controls-buttons-div .play-pause > div {
	display: inline-block;
    position: unset;
	height: var(--controls-icon-size);
}
.controls-buttons-div .controls-icon svg{
	height: var(--controls-icon-size);
	width: var(--controls-icon-size);
	fill: white
}

.controls-buttons-div .volume > span {
	margin-left: 15px;
	height: var(--controls-icon-size);
    line-height: var(--controls-icon-size);
}

.controls-buttons-div .volume {
	display: flex;
	align-items: center;
	justify-content: center;
}

.mute-vol.muted {
	background-color: var(--controls-pri-color);
}

.mute-vol.muted svg {
	fill: var(--controls-sec-color);
}

.volume-level {
	width: 70px;
	text-align: center;
}

.controls-buttons-div .focusable:focus-visible{
	outline: none;
}

.play-pause.rewind {
	margin-left: 15px;
	margin-right: 10px;
}

.ctv-container .custom-toast {
	position: absolute;
	z-index: 500;
	padding: 0.95rem 5rem;
	border-radius: 7px;
	top: 2em;
	right: 0.5em;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;	
}

.ctv-container .custom-toast svg {
	padding-right: 5px;
}

.samsung-container .search-box input {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAADCElEQVRoge2ZzUtUURjGn9vCjKRNhLkoQ4MEV22CFn4lQrVp0VKJIBAD/4ekFn38Fy2CaBPpNCpEkG00o5197PpYVJhEGJRa/VrcOzS8c5yZc+fcGaT5wSzOHe7zvM+cOV/3Sk2aZEJUy81Ah6SzkgYk9UrqlLQv+fqbpHeSViQ9kZSPouhTLX7BAQaBHPCL6tkCZoD+Rtcv4Cgw51H8duSB7kaFuAB8DxCiwDowVu8QV8sU9BK4DgwDx4C9yacnuXYDeFXm/ql6hbi2TQFLwJCHzkngaUPCEP+dLBvAOOA94wERMJFoWEazyFAY2HZMfAH6Amj3A2tGex3oClG7NbOz00aIEEX6A8Cm8ciF0i+YDDq6fjyoSexz2eET7McS8WJXzCIpxkQVPhHwzHhNhxLvIF6FixkIIu72GzJeW0B7COFLRnglQL2VPF8bz4u+Grsc1+yvH6ary/PAtAd9BVxBek37ka9oCqyHraEiriCdpv3BVzQF7yvU4A+lq25bzaKVPduM509fDVePWIJPuw5sHVu1CkjSuml3+IqmwHqs+gq4grw17UO+oik4bNqffQVcQey6MeIrmgLr8cJXwBVkwbTP+YqmwHo8rlkROOjYongvUB5+w8ZrA9gfSnzGiC+R3abxufG6HdKgn1Imghn885l0+BwPbZI3BpsEfCZFvOu1B6s7ofSLjbqJj5/FrBFgS098cLNH3VXgQIjaXYZjjq7fJD7ZpX34MOnoCYAFoCWLHAXzKYcpwDJwykNnmNKBbckDrY0IA/Gh6CYwQvxQri359CTXbgFvKgQoZpqMe2aU0jGTFTPA7izDdFH6YMKXP8A9YL6hYZJAfcR/AbsDKMcP4C5wItFoBWYr3JOrNkytL3raJZ1RfMbulXRE8Yue35LWJH2UtCxpUVIuiqKv5v5WSfclnS5j81DS+SiKNmqpNXOAFkq3RJZZspzNQlFlmLlmmHrzP4aZB/Y0utaKUN3UXN/3j2mp0DNXGl2fF9uE2VkhCpgwOzNEgWTMZPOytEmT8vwFxdlvsvNj/B0AAAAASUVORK5CYII=");
	background-color: black;
    color: white;
	box-shadow: 0 0 0 2px white;
}

.samsung-container .user-access .logInScreen #account-form input {
	background-color: black;
    color: white;
	box-shadow: 0 0 0 2px white;
}

.ctv-container .search-section .no-search-items span{
	margin-bottom: 200px;
}

.ctv-container .hg-black:focus {
	outline: none;
	box-shadow: 0 0 0 4px yellow !important;
	color: yellow;
}

.ctv-container #nav-bar .swiper-wrapper {
	display: flex;
	justify-content: center;
}

.ctv-container.xbox-container .videoFull .vjs-control-bar {
	display: flex;
}

.ctv-container #overlay-video.epg-video .vjs-control-bar {
	display: none;
}

.ctv-container.xbox-container #spotlight-grid > .swiper-container.swiper-container-vertical > div > span,
#fullscreen-grid > .swiper-container.swiper-container-vertical > div > span {
	height: auto !important;
}

.ctv-container .jumbo-view.portrait-view .img-video {
	padding-bottom: 0px !important;
}

.ctv-container .xbox-sub-btn:focus {
	box-shadow: 0 0 0 2px yellow !important;
	transform: scale(1.06);
	outline: none;
}

.xbox-sub-btn{ 
	display: inline-block;
}

.remove-scrollbar::-webkit-scrollbar {
	width: 0 !important;
	display: none !important;
}

.ctv-container #theo-player.showingControls .vjs-control-bar {
	display: flex !important;
}
.ctv-container #theo-player.showingControls .theoplayer-skin .vjs-progress-control .vjs-progress-holder {
	height: 1.5em;
}

.vizio-container .SettingScreen .btn-LogIn {
	border: 2px solid;
}

.vizio-container .SettingScreen .cc-div {
	border: 2px solid;
	border-radius: 8px;
	width: 100%;	
}

.ctv-container .fake-video_ima-ad-container iframe,
.ctv-container .live-video_ima-ad-container iframe, 
.ctv-container .thumb-video_ima-ad-container iframe,
.ctv-container .theoplayer-ima,
.ctv-container .ima-ad-container {
	pointer-events: none !important;
}

.ctv-container #EPG-layout .focusable:focus {
	border: 2px solid yellow !important;
	box-shadow: none !important;
}
.vizio-container .custom-controls .focusable:focus {
	outline: none;
}

.ctv-container .theo-cast-svg-container,.ctv-container .theo-settings-control-button,.ctv-container .vjs-fullscreen-control,
.ctv-container .theo-text-track-control-button {
	display: none !important;
}

.web-route-container .theo-settings-control-button,
.web-route-container .theo-text-track-control-button,
.web-route-container .theo-audio-track-control-button {
	display: none !important;
}

.ctv-container .center-align {
	display: flex;
	align-items: center;
	justify-content: center;	
}

.ctv-container #terms-privacy-back-btn {
	padding: 10px 20px;
	background: grey;
	border-radius: 10px;	
}

.ctv-container .loader-scrn:focus {
	outline: none;
}

.ctv-container .tvod-interstitial .description .description-wrapper,
.ctv-container .tvod-interstitial .tvod-interstital-extras .extra-title {
	font-size: 60px !important;
}

.ctv-container .more-section .more-section-wrapper .item.header {
	justify-content: unset !important;
}

.zeasn-container .video-js .vjs-tech {
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.zeasn-container .tvod-interstitial .description .tvod-summary span {
	-webkit-line-clamp: 5 !important;
}

.zeasn-container .tvod-interstitial.section-interstitial .description .description-wrapper {
	padding: 45px 0px 60px 75px;
}

.ctv-container.two-by-three-poster .detail-view.portrait-view .img-video {
	height: 0;
	padding-bottom: 150%;
}

.ctv-container.two-by-three-poster .jumbo-view.portrait-view .img-video {
	height: 0;
	padding-bottom: 150% !important;
}

@media screen and (max-width: 1280px) {
	.ctv-container .user-access .logInScreen #account-form .btnForgot a {
		right: -270px;
	}
}

.simulator-route-container #spotlight-grid {
	overflow-y: scroll;
	overflow-x: hidden;
}

.ctv-container .more-section .more-section-wrapper .item {
	font-size: 28px;
}

.ctv-container .more-section .more-section-wrapper .item.header {
	font-size: 24px;
}

@media screen and (max-width: 1600px) {
	.ctv-container .tvod-interstitial .description .description-wrapper .titles .title {
		-webkit-line-clamp: 1;
	}
	.ctv-container .tvod-interstitial .description .tvod-summary span {
		-webkit-line-clamp: 4;
	}
	.zeasn-container .tvod-interstitial .description .tvod-summary span,
	.ctv-container .tvod-interstitial.section-interstitial .description .tvod-summary span {
		-webkit-line-clamp: 3 !important;
	}
}


.ctv-container #tvod-overlay .fake-video-ad + .prevThumbnail.video-title{
	top: 140px;
}

.ctv-container #tvod-overlay .fake-video-ad + .prevThumbnail.video-title + .prevThumbnail.video-title.ellipsis.streamOnEntryText{
	top: 230px !important;
}

/* Square thumnail */
.ctv-container .grid-view.square-view .img-video,
.ctv-container .detail-view.square-view .img-video,
.ctv-container .jumbo-view.square-view .img-video {
	padding-bottom: 100%;
}
/* Square thumnail */

.link-modal-container{
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: hidden; /* Enable scroll if needed */
}

.link-modal-details{
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
	top: 15%;
    height: 85%;
}
.link-modal-details > span{
	margin: 0 10px;
}

.link-url{
	font-size: 32px;
	padding: 10px 0;
	cursor: pointer;
}