body {
    --detail_portait_width: 28vw;
}
.nav-view {
    height: var(--ctv_nav_height);
}

@media screen and (min-width: 1400px) {
    .subscribeScreen a.btn-trial {
        font-size: 24px;
        padding: 18px 25px;
        min-width: 600px;
        margin: 18px 0;
    }
    .subscribeScreen-wrapper .logoHolder.smallImg {
        padding: 0px 0 50px;
    }
}

@media screen and (min-width: 1921px) {
    .detail-view-cls .detail-view.portrait-view .swiper-wrapper > .detail-swiper {
        width: var(--detail_portait_width);
    }
    .detail-view-cls .detail-view.portrait-view .swiper-wrapper .img-video {
        height: calc(var(--detail_portait_width) * 1.33);
    }
    .two-by-three-poster .detail-view-cls .detail-view.portrait-view .swiper-wrapper .img-video {
        height: calc(var(--detail_portait_width) * 1.5);
    }
    .detail-view-cls .detail-view.portrait-view .detailContent,
    .detail-view-cls .detail-view.portrait-view .detailContent .saveIcon {
        width: calc(100vw - 158px - var(--detail_portait_width));
    }
    .user-access .userLoginCntnt.queueLogin {
        min-height: calc(100vh - 90px - 140px);
    }
    .with-modal .search-section {
        padding-top: 100px;
    }
    .logIn-container .SettingScreen {
        margin-top: 140px;
    }
    .detail-view-cls .detail-view .swiper-container.swiper-container-vertical,
    .detail-view-cls .detailContent,
    #nav-bar + div .subscribeScreen-wrapper {
        padding-top: 160px;
    }
    .detail-view-cls > div > h2 {
        top: 360px;
    }
    div:not(.fix-nav) > #nav-bar + div .user-access,
    body.transparent .user-access .subscribeScreen-wrapper {
        margin-top: 70px;
    }
    .user-access .logInScreen p {
        font-size: 32px;
    }
    body.transparent .user-access .userLoginCntnt {
        margin-top: 50px;
    }
    .user-access .logInScreen a.btn-email,
    .user-access .logInScreen .subscriberHeader a,
    .user-access .logInScreen .btn-fbIcon {
        font-size: 26px;
        min-width: 450px;
    }
    .user-access .logInScreen .btn-fbIcon img{
        width: 45px;
        height: 45px;
        background-size: 45px;
    }
    .user-access .logInScreen a.btn-email .mail-icon{
        background-size: 45px 35px;
        height: 35px;
        width: 45px;
        vertical-align: middle;
    }
    .user-access .logInScreen .termsOfUse li a {
        font-size: 22px;
    }
    .user-access .logoHolder.smallImg img {
        max-height: 95px;
    }
    .user-access .logInScreen .subscriberHeader {
        margin: 30px 0;
    }
}

@media screen and (max-width: 1920px) {
    body {
        --ctv_nav_height: 120px;
    }

    a.btn-try, a.btn-cancel, .nav-view a, p.offer {
        font-size: 28px;
    }

    /* Below three Css depends on nav height*/
    .fix-nav > .nav-view + div {
        margin-top: 120px;
    }

    div:not(.fix-nav) > #nav-bar + div .user-access,
    body.transparent .user-access .subscribeScreen-wrapper,
    body.transparent .user-access .userLoginCntnt {
        margin-top: 80px;
    }


    #spotlight-grid .spotlight.grid-view > *:first-child,
    #fullscreen-grid .spotlight.grid-view > *:first-child {
        font-size: 32px;
    }

    .detail-view-cls .detail-view .swiper-wrapper > .detail-swiper {
        width: 40vw;
    }
    
    .detail-view-cls .detailContent,
    .detail-view-cls .detailContent .saveIcon {
        width: calc(60vw - 160px);
    }

    .detail-view-cls .detailContent .summaryHolder h4,
    .detail-view-cls .detailContent .ctaHolder {
        font-size: 40px;
    }
    
    .detail-view-cls .detailContent .summaryHolder p {
        font-size: 32px;
    }

    .detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
        font-size: 28px;
    }
    .detail-view-cls .detail-view.portrait-view .swiper-wrapper > .detail-swiper {
        width: var(--detail_portait_width);
    }
    .detail-view-cls .detail-view.portrait-view .swiper-wrapper .img-video {
        height: calc(var(--detail_portait_width) * 1.33);
    }
    .two-by-three-poster .detail-view-cls .detail-view.portrait-view .swiper-wrapper .img-video {
        height: calc(var(--detail_portait_width) * 1.5);
    }
    .detail-view-cls .detail-view.portrait-view .detailContent,
    .detail-view-cls .detail-view.portrait-view .detailContent .saveIcon {
        width: calc(100vw - 158px - var(--detail_portait_width));
    }
    /* Big-title */
    #spotlight-section > #previewSection > #previewDescription .previewTitle{
        font-size: 38px;
    }

    /*	Subtitle */
    #spotlight-section > #previewSection > #previewDescription .previewSubtitle{
        font-size: 25px;
    }
    /*Description*/
    #spotlight-section > #previewSection > #previewDescription #previewSummary{
        font-size: 25px;
    }

    #fullscreen-grid .spotlight.grid-view > .swiper-container div.titleHolder p {
        font-size: 26px;
    }
    .user-access .userLoginCntnt.queueLogin {
        min-height: calc(100vh - 90px - 120px);
    }
    .logoHolder {
        padding : 50px 0 40px;
    }

    .subscribeScreen .subscription-option-div .trial-text{
        font-size: 26px;
    }

    .subscribeScreen .sub-unlock-note{
        font-size: 26px;
    }
    .subscribeScreen a.btn-trial{
        font-size: 28px;
    }

    .subscribeScreen p, .centerContent p, .invalidContent p .reContent p{
        font-size: 38px;
    }
    .subscribeScreen-wrapper .disclaimer{
        padding: 80px 50px;
        font-size: 22px;
    }

    .smallImg img{
        max-height: 160px;
    }

    .termsOfUse li a{
        font-size: 26px;
    }

    .with-modal .search-section {
        padding-top: 80px;
    }
    .logIn-container .SettingScreen {
        margin-top: 120px;
    }
    .detail-view-cls .detail-view .swiper-container.swiper-container-vertical,
    .detail-view-cls .detailContent,
    #nav-bar + div .subscribeScreen-wrapper {
        padding-top: 140px;
    }
    .detail-view-cls > div > h2 {
        top: 340px;
    }

    .metering-counter, .video-title {
        width: 370px;
        height: 80px;
        line-height: 75px;
    }
    .video-title p {
        font-size: 24px;
    }
    .metering-counter span {
        font-size: 26px;
    }

    .logoHolder.smallImg img{
        max-height: 95px
    }
    .settings-logoHolder img{
        max-height: 102px;
    }

    .sponsorContent img{
        max-height: 352px;
    }

    /***** Registration screen**/

    .user-access .logInScreen p {
        font-size: 32px;
    }
    .user-access .logInScreen a.btn-email,
    .user-access .logInScreen .subscriberHeader a,
    .user-access .logInScreen .btn-fbIcon {
        font-size: 26px;
        min-width: 450px;
    }
    .user-access .logInScreen .btn-fbIcon img{
        width: 45px;
        height: 45px;
        background-size: 45px;
    }
    .user-access .logInScreen a.btn-email .mail-icon{
        background-size: 45px 35px;
        height: 35px;
        width: 45px;
        vertical-align: middle;
    }
    .user-access .logInScreen .termsOfUse li a {
        font-size: 22px;
    }
    .user-access .logoHolder.smallImg img {
        max-height: 95px;
    }
    .user-access .logInScreen .subscriberHeader {
        margin: 30px 0;
    }

    .userLoginCntnt.queueLogin{
        /* margin-top: 72px; */
    }
    .user-access .logInScreen a.btn-email .website-icon{
        background-size: 35px 35px;
        height: 35px;
        width: 35px;
    }
}


@media screen and (max-width: 1600px) {
    body {
        --ctv_nav_height: 100px;
    }
    
    a.btn-try, a.btn-cancel, .nav-view a, p.offer {
        font-size: 22px;
    }

    .fix-nav > .nav-view + div {
        margin-top: 100px;
    }

    div:not(.fix-nav) > #nav-bar + div .user-access,
    body.transparent .user-access .subscribeScreen-wrapper,
    body.transparent .user-access .userLoginCntnt {
        margin-top: 60px;
    }


    #spotlight-grid .spotlight.grid-view > *:first-child,
    #fullscreen-grid .spotlight.grid-view > *:first-child {
        font-size: 30px;
    }

    .detail-view-cls .detail-view .swiper-wrapper > .detail-swiper {
        width: 37vw;
    }
    
    .detail-view-cls .detailContent,
    .detail-view-cls .detailContent .saveIcon {
        width: calc(63vw - 160px);
    }
    .detail-view-cls .detailContent .summaryHolder h4,
    .detail-view-cls .detailContent .ctaHolder {
        font-size: 36px;
    }
    
    .detail-view-cls .detailContent .summaryHolder p {
        font-size: 28px;
    }
    .detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
        font-size: 25px;
    }
    .detail-view-cls .detailContent .saveIcon img, .detail-view-cls .detailContent .saveIcon .tick-icon {
        padding: 25px 32px;
        border: 0;
        width: 60px;
    }
    /* Big-title */
    #spotlight-section > #previewSection > #previewDescription .previewTitle{
        font-size: 36px;
    }

    /*	Subtitle */
    #spotlight-section > #previewSection > #previewDescription .previewSubtitle{
        font-size: 23px;
    }
    /*Description*/
    #spotlight-section > #previewSection > #previewDescription #previewSummary{
        font-size: 23px;
    }

    .video-inlineMenu .menu { 
        margin-top: 26px;
    }

    .video-inlineMenu .button-toggle img{
        height: 45px;
        width: 45px;
        padding: 10px;
    }

    #fullscreen-grid .spotlight.grid-view > .swiper-container div.titleHolder p {
        font-size: 24px;
    }
    
    .video-inlineMenu .video-inlineMenuItem .title p {
        font-size: 22px;
        line-height: 30px;
    }
    /* Jumbo  */
    .jumbo-view .titleHolder {
        height: 110px;
    }
    .jumbo-view.portrait-view .titleHolder {
        height: 150px;
    }
    .jumbo-view h2 {
        font-size: 38px;
    }
    .jumbo-view .ctaHolder p,
    .jumbo-view .titleHolder p {
        font-size: 32px;
    }
    .jumbo-view .titleHolder a img.icon,
    #standard-grid.grid-view .titleHolder a img.icon {
        width: 65px;
        height: 65px;
        background-size: 65px 65px;
    }
    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        font-size: 38px;
        padding-top: 20px;
    }
    #standard-grid.grid-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view .titleHolder p,
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        font-size: 32px;
        max-height: 90px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        max-height: 130px;
    }
    #standard-grid.grid-view .titleHolder {
        max-height: 80px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder {
        max-height: 130px;
    }
    /* registration wall */
    .user-access .logInScreen p {
        font-size: 30px;
    }
    .user-access .logInScreen a.btn-email,
    .user-access .logInScreen .subscriberHeader a,
    .user-access .logInScreen .btn-fbIcon {
        font-size: 26px;
        padding: 25px;
    }
    .user-access .logInScreen .btn-fbIcon {
        padding: 20px 25px;
    }
    .user-access .logInScreen .btn-fbIcon img{
        width: 55px;
        height: 55px;
        background-size: 55px;
    }
    .user-access .logInScreen a.btn-email .mail-icon{
        background-size: 55px 45px;
        height: 45px;
        width: 55px;
        vertical-align: middle;
    }
    .user-access .logInScreen .termsOfUse li a {
        font-size: 22px;
    }
    .user-access .logoHolder.smallImg img {
        max-height: 95px;
    }
    .search-section .search-row .searchContent .titleHolder p {
        font-size: 32px;
    }
    .search-section .search-row .searchContent .titleHolder a img.icon {
        width: 65px;
        height: 65px;
        background-size: 65px 65px;
    }
    .search-section .search-row .searchContent .img-video {
        height: 270px;
    }
    .user-access .userLoginCntnt.queueLogin {
        min-height: calc(100vh - 90px - 100px);
    }
    .with-modal .search-section {
        padding-top: 60px;
    }
    .logIn-container .SettingScreen {
        margin-top: 100px;
    }
    .detail-view-cls .detail-view .swiper-container.swiper-container-vertical,
    .detail-view-cls .detailContent,
    #nav-bar + div .subscribeScreen-wrapper {
        padding-top: 110px;
    }
    .detail-view-cls > div > h2 {
        top: 310px;
    }


    .metering-counter, .video-title {
        width: 330px;
        height: 72px;
        line-height: 66px;
    }
    .video-title p {
        font-size: 22px;
    }
    .metering-counter span {
        font-size: 24px;
    }

    .logoHolder.smallImg img{
        max-height: 95px
    }
    .settings-logoHolder img{
        max-height: 98px;
    }


    .sponsorContent img{
        max-height: 303px;
    }

    .data-privacy,
    .data-privacy .contine-button {
        font-size: 26px;
    }
    .data-privacy .privacy-header span {
        font-size: 32px;
    }
    .user-access .logInScreen a.btn-email .website-icon{
        background-size: 40px 40px;
        height: 40px;
        width: 40px;
    }
    .onboarding-screen .next-btn {
        font-size: 20px;
    }
    .onboarding-screen .skip-btn {
        font-size: 24px;
        top: 30px;
        right: 30px;
    }
    .onboarding-screen .second-layer .screen-item .title {
        font-size: 32px;
    }
    .onboarding-screen .second-layer .screen-item .summary {
        font-size: 28px;
    }
    .onboarding-screen .swiper-container-horizontal>.swiper-pagination-bullets {
        bottom: 150px;
    }
    .onboarding-screen .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
    }
}

@media screen and (max-width: 1536px) {
    body {
        --ctv_nav_height: 85px;
    }
    
    a.btn-try, a.btn-cancel, .nav-view a, p.offer {
        font-size: 20px;
    }

    .fix-nav > .nav-view + div {
        margin-top: 85px;
    }
    #spotlight-grid .spotlight.grid-view > *:first-child,
    #fullscreen-grid .spotlight.grid-view > *:first-child {
        font-size: 28px;
    }
    /* Big-title */
    #spotlight-section > #previewSection > #previewDescription .previewTitle{
        font-size: 34px;
    }

    /*	Subtitle */
    #spotlight-section > #previewSection > #previewDescription .previewSubtitle{
        font-size: 21px;
    }
    /*Description*/
    #spotlight-section > #previewSection > #previewDescription #previewSummary{
        font-size: 21px;
    }

    #fullscreen-grid .spotlight.grid-view > .swiper-container div.titleHolder p {
        font-size: 22px;
    }
    .detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
        font-size: 22px;
    }
    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        font-size: 36px;
    }
    #standard-grid.grid-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view .titleHolder p,
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        font-size: 30px;
        max-height: 90px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        max-height: 120px;
    }
    #standard-grid.grid-view .titleHolder {
        max-height: 70px;
        margin-top: 36px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder {
        max-height: 120px;
    }
    .jumbo-view h2 {
        font-size: 36px;
    }
    .jumbo-view .ctaHolder p,
    .jumbo-view .titleHolder p {
        font-size: 30px;
    }
    .jumbo-view .titleHolder a img.icon,
    #standard-grid.grid-view .titleHolder a img.icon {
        width: 60px;
        height: 60px;
        background-size: 60px 60px;
    }
    /* registration wall */
    .user-access .logInScreen p {
        font-size: 28px;
    }
    .user-access .logInScreen a.btn-email,
    .user-access .logInScreen .subscriberHeader a,
    .user-access .logInScreen .btn-fbIcon {
        font-size: 24px;
        padding: 25px;
    }
    .user-access .logInScreen .btn-fbIcon{
        padding: 20px 25px;
    }
    .user-access .logInScreen .btn-fbIcon img{
        width: 50px;
        height: 50px;
        background-size: 50px;
    }
    .user-access .logInScreen a.btn-email .mail-icon{
        background-size: 50px 40px;
        height: 40px;
        width: 50px;
        vertical-align: middle;
    }
    .user-access .logInScreen .termsOfUse li a {
        font-size: 26px;
    }
    .user-access .logoHolder.smallImg img {
        max-height: 95px;
    }
    .search-section .search-row {
        grid-gap: 40px;
        width: 92%;
    }
    .search-section .search-row .searchContent .titleHolder p {
        font-size: 30px;
    }
    .search-section .search-row .searchContent .titleHolder a img.icon {
        width: 60px;
        height: 60px;
        background-size: 60px 60px;
    }
    .search-section .search-row .searchContent .img-video {
        height: 240px;
    }
    .user-access .userLoginCntnt.queueLogin {
        min-height: calc(100vh - 90px - 85px);
    }
    .user-access .logInScreen #account-form input,
    .user-access .logInScreen #account-form .btnForgot a,
    .user-access .logInScreen #account-form button {
        font-size: 23px;
    }
    .user-access .logInScreen #account-form span.btnBox button {
        min-width: 250px;
    }
    .user-access .logInScreen #account-form input {
        min-width: 500px;
    }
    .with-modal .search-section {
        padding-top: 45px;
    }
    .logIn-container .SettingScreen {
        margin-top: 85px;
    }
    .detail-view-cls .detail-view .swiper-container.swiper-container-vertical,
    .detail-view-cls .detailContent,
    #nav-bar + div .subscribeScreen-wrapper {
        padding-top: 100px;
    }
    .detail-view-cls > div > h2 {
        top: 300px;
    }
    .metering-counter, .video-title {
        width: 300px;
        height: 68px;
        line-height: 64px;
    }
    .video-title p {
        font-size: 22px;
    }
    .metering-counter span {
        font-size: 24px;
    }
    .video-inlineMenu .button-toggle img{
        height: 40px;
        width: 40px;
        padding: 8px;
    }
    .video-inlineMenu .video-inlineMenuItem .title p {
        font-size: 21px;
        line-height: 28px;
    }
    .logoHolder.smallImg img{
        max-height: 95px
    }
    .settings-logoHolder img{
        max-height: 94px;
    }

    .sponsorContent img{
        max-height: 290px;
    }
    .user-access .logInScreen a.btn-email .website-icon{
        background-size: 35px 35px;
        height: 35px;
        width: 35px;
    }
}

@media screen and (max-width: 1440px) {
    .subscribeScreen-wrapper .logoHolder.smallImg {
        padding: 0px 0 40px;
    }

    #spotlight-grid .spotlight.grid-view > *:first-child,
    #fullscreen-grid .spotlight.grid-view > *:first-child {
        font-size: 26px;
    }
    /* Big-title */
    #spotlight-section > #previewSection > #previewDescription .previewTitle{
        font-size: 32px;
    }

    /*	Subtitle */
    #spotlight-section > #previewSection > #previewDescription .previewSubtitle{
        font-size: 19px;
    }
    /*Description*/
    #spotlight-section > #previewSection > #previewDescription #previewSummary{
        font-size: 19px;
    }
    .video-inlineMenu .menu { 
        margin-top: 22px;
    }
    .video-inlineMenu .button-toggle img{
        height: 35px;
        width: 35px;
    }
    .video-inlineMenu .video-inlineMenuItem .title{
	    --video_inline_title_height: 70px;
    }
    .video-inlineMenu .video-inlineMenuItem .title p {
        font-size: 20px;
        line-height: 26px;
    }

    #fullscreen-grid .spotlight.grid-view > .swiper-container div.titleHolder p {
        font-size: 20px;
    }

    .detail-view-cls .detailContent .summaryHolder h4,
    .detail-view-cls .detailContent .ctaHolder {
        font-size: 33px;
    }
    
    .detail-view-cls .detailContent .summaryHolder p {
        font-size: 24px;
    }
    .detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
        font-size: 22px;
    }
    /* Jumbo  */
    .jumbo-view .titleHolder {
        height: 110px;
    }
    .jumbo-view.portrait-view .titleHolder {
        height: 140px;
    }
    .jumbo-view .ctaHolder p,
    .jumbo-view .titleHolder p {
        font-size: 32px;
    }
    .jumbo-view .lock-icon {
        width: 30px;
    }
    .jumbo-view h2 {
        font-size: 34px;
    }

    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        font-size: 28px;
    }
    #standard-grid.grid-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view .titleHolder p,
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        font-size: 28px;
        max-height: 75px;
        width: auto;
    }
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        max-height: 110px;
    }
    #standard-grid.grid-view .titleHolder {
        max-height: 70px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder {
        max-height: 110px;
    }
    .jumbo-view .titleHolder a img.icon,
    #standard-grid.grid-view .titleHolder a img.icon,
    #standard-grid.grid-view .titleHolder a .tick-icon svg,
    .jumbo-view .titleHolder a .tick-icon svg {
        width: 55px;
        height: 55px;
        background-size: 55px 55px;
    }
    /* registration wall */
    .user-access .logInScreen p {
        font-size: 28px;
    }
    .user-access .logInScreen a.btn-email,
    .user-access .logInScreen .subscriberHeader a,
    .user-access .logInScreen .btn-fbIcon {
        font-size: 24px;
        min-width: 450px;
    }
    .user-access .logInScreen .btn-fbIcon img{
        width: 45px;
        height: 45px;
        background-size: 45px;
    }
    .user-access .logInScreen a.btn-email .mail-icon{
        background-size: 45px 35px;
        height: 35px;
        width: 45px;
        vertical-align: middle;
    }
    .user-access .logInScreen .termsOfUse li a {
        font-size: 22px;
    }
    .user-access a.crossBtn::after,
    .user-access a.crossBtn::before {
        width: 30px;
        height: 3px;
        font-size: 22px;
    }
    .user-access .logoHolder.smallImg img {
        max-height: 95px;
    }
    .user-access .logInScreen .subscriberHeader {
        margin: 30px 0;
    }
    .search-section .search-row {
        grid-gap: 35px;
    }
    .search-section .search-row .searchContent .titleHolder a img.icon {
        width: 55px;
        height: 55px;
        background-size: 55px 55px;
    }
    .search-section .search-row .searchContent .img-video {
        height: 220px;
    }
    .search-section .search-row .searchContent .titleHolder p {
        font-size: 28px;
    }
    .subscribeScreen a.btn-trial {
        min-width: 520px;
    }
    .subscribeScreen-wrapper .iapTitle {
        font-size: 24px;
    }

    .smallImg img{
        max-height: 130px;
    }

    .subscribeScreen .subscription-option-div .trial-text{
        font-size: 20px;
    }

    .subscribeScreen .sub-unlock-note{
        font-size: 20px;
    }
    .subscribeScreen a.btn-trial{
        font-size: 22px;
        padding: 14px 20px;
        min-width: 250px;
        margin: 8px 0;
    }

    .subscribeScreen p, .centerContent p, .invalidContent p .reContent p{
        font-size: 32px;
    }
    .subscribeScreen-wrapper .disclaimer{
        padding: 80px 50px;
        font-size: 16px;
    }
    .termsOfUse li a{
        font-size: 24px;
    }
    .logoHolder.smallImg img{
        max-height: 95px
    }
    .settings-logoHolder img{
        max-height: 94px;
    }

    .sponsorContent img{
        max-height: 278px;
    }
    .data-privacy,
    .data-privacy .contine-button {
        font-size: 24px;
    }
    .data-privacy .privacy-item {
        grid-template-columns: 100px auto 250px;
    }
    .data-privacy .privacy-header span {
        font-size: 30px;
    }
    .data-privacy .privacy-header img {
        height: 55px;
    }
    .data-privacy .contine-button,
    .data-privacy .privacy-item a {
        padding: 10px 20px;
    }
    .data-privacy .contine-button {
        width: 250px;
    }
    .confirm-modal button {
        border-radius: 4px;
    }
}
@media screen and (max-width: 1400px) {
    span.icon-hollow_arrow{
        left: 20px;
        top: 20px;
        font-size: 60px;
    }
    .settings-logoHolder img{
        max-height: 90px;
    }
    .sponsorContent img{
        max-height: 268px;
    }
    .cc-div > span {
        font-size: 24px;
    }
}

@media screen and (max-width: 1366px) {
    .detail-view-cls .detailContent .summaryHolder h4,
    .detail-view-cls .detailContent .ctaHolder {
        font-size: 31px;
    }
    
    .detail-view-cls .detailContent .summaryHolder p {
        font-size: 23px;
    }
    .detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
        font-size: 22px;
    }
    .detail-view-cls .detailContent .saveIcon img {
        padding: 20px 27px;
        border: 0;
        width: 58px;
    }
    /* Jumbo  */
    .jumbo-view .titleHolder {
        height: 100px;
    }
    .jumbo-view.portrait-view .titleHolder {
        height: 120px;
    }
    .jumbo-view .titleHolder a img.icon,
    #standard-grid.grid-view .titleHolder a img.icon {
        width: 50px;
        height: 50px;
        background-size: 50px 50px;
    }
    .jumbo-view .ctaHolder p,
    .jumbo-view .titleHolder p {
        font-size: 26px;
    }
    .jumbo-view .lock-icon {
        width: 24px;
    }
    .jumbo-view h2 {
        width: 350px;
        font-size: 32px;
    }
    .jumbo-view .ctaHolder {
        padding: 10px 0 0 100px;
    }
    .jumbo-view .swiper-container {
        padding: 25px 100px 20px;
    }
    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        font-size: 32px;
    }
    #standard-grid.grid-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view .titleHolder p,
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        font-size: 26px;
        max-height: 70px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        max-height: 110px;
    }
    #standard-grid.grid-view .titleHolder {
        max-height: 65px;
        margin-top: 30px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder {
        max-height: 100px;
    }
    #standard-grid.grid-view .swiper-container,
    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        padding-left: 70px;
    }
    #standard-grid.grid-view .titleHolder a img.icon {
        margin-left: 10px;
    }
    /* registration wall */
    .user-access .logInScreen p {
        font-size: 26px;
        margin-bottom: 40px;
    }
    .user-access .logInScreen a.btn-email,
    .user-access .logInScreen .subscriberHeader a,
    .user-access .logInScreen .btn-fbIcon {
        font-size: 22px;
        min-width: 450px;
    }
    .user-access .logInScreen .btn-fbIcon img{
        width: 45px;
        height: 45px;
        background-size: 45px;
    }
    .user-access .logInScreen a.btn-email .mail-icon{
        background-size: 45px 35px;
        height: 35px;
        width: 45px;
        vertical-align: middle;
    }
    .user-access .logInScreen .termsOfUse li a {
        font-size: 20px;
    }
    .user-access .logoHolder.smallImg img {
        max-height: 95px;
    }
    .user-access .logInScreen .subscriberHeader {
        margin: 30px 0;
    }
    .search-section .search-row {
        padding: 40px;
        width: 94%;
    }
    .search-section .search-row .searchContent .titleHolder p {
        font-size: 26px;
    }
    .search-section .search-row .searchContent .titleHolder a img.icon {
        width: 50px;
        height: 50px;
        background-size: 50px 50px;
    }
    .search-section .search-row .searchContent .img-video {
        height: 210px;
    }
    .metering-counter, .video-title {
        width: 248px;
        height: 58px;
        line-height: 55px;
    }
    .video-title p {
        font-size: 18px;
    }
    .metering-counter span {
        font-size: 20px;
    }
    .video-inlineMenu .button-toggle img{
        height: 30px;
        width: 30px;
        padding: 6px;
    }
    .logoHolder.smallImg img{
        max-height: 95px
    }
    .settings-logoHolder img{
        max-height: 88px;
    }
    .sponsorContent img{
        max-height: 252px;
    }
    .user-access .logInScreen a.btn-email .website-icon{
        background-size: 28px 28px;
        height: 28px;
        width: 28px;
    }
    .onboarding-screen .next-btn {
        font-size: 18px;
        bottom: 50px;
    }
    .onboarding-screen .skip-btn {
        font-size: 20px;
        top: 20px;
        right: 20px;
    }
    .onboarding-screen .second-layer .screen-item .title {
        font-size: 28px;
    }
    .onboarding-screen .second-layer .screen-item .summary {
        font-size: 22px;
    }
    .onboarding-screen .swiper-container-horizontal>.swiper-pagination-bullets {
        bottom: 120px;
    }
    .onboarding-screen .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
    }
}

@media screen and (max-width: 1280px) {
    .detail-view-cls .detailContent .saveIcon {
        /* bottom: 80px; */
    }

    .detail-view-cls > div > h2 {
        font-size: 38px;
        top: 262px;
    }

    .detail-view-cls .detailContent .summaryHolder h4,
    .detail-view-cls .detailContent .ctaHolder {
        font-size: 29px;
    }
    
    .detail-view-cls .detailContent .summaryHolder p {
        font-size: 21px;
    }
    .detail-view-cls .detail-view .swiper-wrapper .titleHolder p {
        font-size: 20px;
    }
    .detail-view-cls .detailContent {
        padding: 50px 50px 0;
    }
    #spotlight-grid .spotlight.grid-view > .swiper-container {
        padding-top: 15px;
    }
    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        font-size: 30px;
    }
    #standard-grid.grid-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view .titleHolder p,
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        font-size: 24px;
        max-height: 65px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        max-height: 100px;
    }
    #standard-grid.grid-view .titleHolder {
        max-height: 60px;
        margin-top: 24px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder {
        max-height: 90px;
    }
    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        padding-bottom: 0px;
    }
    .jumbo-view .ctaHolder p,
    .jumbo-view .titleHolder p {
        font-size: 24px;
    }
    .jumbo-view h2 {
        width: 350px;
        font-size: 30px;
    }
    .jumbo-view .titleHolder a img.icon,
    #standard-grid.grid-view .titleHolder a img.icon {
        width: 45px;
        height: 45px;
        background-size: 45px 45px;
    }
    /* registration wall */
    .user-access .logInScreen p {
        font-size: 26px;
    }
    .user-access .logInScreen a.btn-email,
    .user-access .logInScreen .subscriberHeader a,
    .user-access .logInScreen .btn-fbIcon {
        font-size: 21px;
        padding: 15px;
    }
    .user-access .logInScreen .btn-fbIcon {
        padding: 10px 15px;
    }
    .user-access .logInScreen .btn-fbIcon img{
        width: 40px;
        height: 40px;
        background-size: 40px;
    }
    .user-access .logInScreen a.btn-email .mail-icon{
        background-size: 40px 30px;
        height: 30px;
        width: 40px;
    }
    .user-access .logInScreen .termsOfUse li a {
        font-size: 20px;
    }
    .user-access .logoHolder.smallImg img {
        max-height: 90px;
    }
    .user-access .logInScreen #account-form input,
    .user-access .logInScreen #account-form .btnForgot a,
    .user-access .logInScreen #account-form button {
        font-size: 21px;
    }
    .user-access .logInScreen #account-form span.btnBox button {
        min-width: 220px;
    }
    .user-access .logInScreen #account-form input {
        min-width: 450px;
    }
    .user-access .logInScreen #account-form .btnForgot a {
        min-width: 160px;
        right: -230px;
    }
    .search-section .search-row {
        grid-gap: 30px;
    }
    .search-section .search-row .searchContent .titleHolder p {
        font-size: 24px;
    }

    .search-section .search-row .searchContent .titleHolder a img.icon {
        width: 45px;
        height: 45px;
        background-size: 45px 45px;
    }
    .search-section .search-row .searchContent .img-video {
        height: 200px;
    }
    .detail-view-cls .detailContent {
        padding-top: 100px;
    }
    .detail-view-cls > div > h2 {
        top: 307px;
    }
    .video-inlineMenu .button-toggle img{
        height: 25px;
        width: 25px;
        padding: 6px;
    }
    .video-inlineMenu .video-inlineMenuItem .title p {
        font-size: 18px;
        line-height: 24px;
    }
    .logoHolder.smallImg img{
        max-height: 95px
    }
    .settings-logoHolder img{
        max-height: 82px;
    }
    .sponsorContent img{
        max-height: 232px;
    }
    .data-privacy,
    .data-privacy .contine-button {
        font-size: 22px;
    }
    .data-privacy .privacy-header span {
        font-size: 28px;
    }
    .data-privacy .privacy-header img {
        height: 50px;
    }
}

@media screen and (max-width: 1150px) {
    body {
        --ctv_nav_height: 70px;
    }
    
    a.btn-try, a.btn-cancel, .nav-view a, p.offer {
        font-size: 16px;
    }

    .fix-nav > .nav-view + div {
        margin-top: 70px;
    }

    div:not(.fix-nav) > #nav-bar + div .user-access,
    body.transparent .user-access .subscribeScreen-wrapper,
    body.transparent .user-access .userLoginCntnt {
        margin-top: 40px;
    }
    
    .video-inlineMenu .menu { 
        margin-top: 18px;
    }
    .video-inlineMenu .video-inlineMenuItem .title{
        --video_inline_title_height: 60px;
    }
    .video-inlineMenu .video-inlineMenuItem .title p {
        font-size: 16px;
    }
    .user-access .userLoginCntnt.queueLogin {
        min-height: calc(100vh - 90px - 70px);
    }
    .with-modal .search-section {
        padding-top: 35px;
    }
    .logIn-container .SettingScreen {
        margin-top: 70px;
    }
    .detail-view-cls .detail-view .swiper-container.swiper-container-vertical,
    .detail-view-cls .detailContent,
    #nav-bar + div .subscribeScreen-wrapper {
        padding-top: 85px;
    }
    .detail-view-cls > div > h2 {
        top: 285px;
    }
    .logoHolder.smallImg img{
        max-height: 95px
    }
    .sponsorContent img{
        max-height: 222px;
    }
}


/* Below three media queries are for tablet */
@media screen and (max-width: 1024px) {
    body {
        --ctv_nav_height: 60px;
    }
    
    a.btn-try, a.btn-cancel, .nav-view a, p.offer {
        font-size: 14px;
    }

    .fix-nav > .nav-view + div {
        margin-top: 60px;
    }

    div:not(.fix-nav) > #nav-bar + div .user-access,
    body.transparent .user-access .subscribeScreen-wrapper {
        margin-top: 20px;
    }


    .detail-view-cls .detail-view .swiper-wrapper .titleHolder {
        height: 90px;
    }
    /* Jumbo  */
    .jumbo-view .titleHolder {
        height: 80px;
    }
    .jumbo-view.portrait-view .titleHolder {
        height: 100px;
    }
    .jumbo-view .titleHolder a img.icon,
    #standard-grid.grid-view .titleHolder a img.icon {
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
    }
    .jumbo-view .ctaHolder p,
    .jumbo-view .titleHolder p {
        font-size: 20px;
    }
    .jumbo-view .lock-icon {
        width: 20px;
    }
    .jumbo-view h2 {
        font-size: 26px;
    }
    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        font-size: 26px;
    }
    #standard-grid.grid-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view .titleHolder p,
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        font-size: 20px;
        max-height: 55px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder p,
    #standard-grid.nothumbBox.grid-view.portrait-view .titleHolder p {
        max-height: 80px;
    }
    #standard-grid.grid-view .titleHolder {
        max-height: 50px;
    }
    #standard-grid.grid-view.portrait-view .titleHolder {
        max-height: 70px;
    }
    #standard-grid.grid-view .swiper-container,
    #standard-grid.grid-view .ctaHolder,
    #standard-grid.grid-view h2 {
        padding-left: 50px;
    }
    /* registration wall */
    .user-access .logInScreen p {
        font-size: 22px;
    }
    .user-access .logInScreen a.btn-email,
    .user-access .logInScreen .subscriberHeader a,
    .user-access .logInScreen .btn-fbIcon {
        font-size: 20px;
    }
    .user-access .logInScreen .btn-fbIcon img{
        width: 35px;
        height: 35px;
        background-size: 35px;
    }
    .user-access .logInScreen a.btn-email .mail-icon{
        background-size: 35px 25px;
        height: 25px;
        width: 35px;
    }
    .user-access .logInScreen .termsOfUse li a {
        font-size: 18px;
    }
    .user-access .logoHolder.smallImg img {
        max-height: 80px;
    }
    .search-section .search-row {
        grid-gap: 25px;
        padding: 25px;
        width: 95%;
    }
    .search-section .search-row .searchContent .titleHolder p {
        font-size: 20px;
    }
    .search-section .search-row .searchContent .titleHolder a img.icon {
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
    }
    .search-section .search-row .searchContent .img-video {
        height: 170px;
    }
    .video-inlineMenu .video-inlineMenuItem .title p {
        font-size: 14px;
        line-height: 22px;
    }
    .sponsorContent img{
        max-height: 212px;
    }

    .EPG-layout {
        --epg-name-width: 150px;
        --epg--current-time-width: 120px;
        --epg--col-height: 75px;
	    --epg--show-des-height: 80px;
    }
    .EPG-layout .channel .channel-name {
        flex-direction: column;
        justify-content: center;
	    padding: 10px 10px;
    }
    .EPG-layout .channel .channel-name .channel-number {
        font-size: 22px;
        margin-right: 0px;
    }
    .EPG-layout .epg-header .time-head .time,
    .EPG-layout .epg-header .current-time {
        padding: 10px 8px;
        font-size: 14px;
        line-height: 2;
        vertical-align: middle;
    }
    .EPG-layout .epg-header .time-line-h {
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }
    .EPG-layout .epg-header .time-line-t {
        height: 41px;
        margin-left: 3px;
    }
    .EPG-layout .channel .channel-programes .show,
    .EPG-layout .channel-wrapper {
        font-size: 14px;
    }
    .EPG-layout .show-description .wrapper .show-title {
        font-size: 16px;
    }
    .EPG-layout .show-description .wrapper {
        padding: 10px;
    }
    .EPG-layout .show-description .wrapper .img-video {
        width: 100px;
        margin-right: 10px;
    }
    .EPG-layout .channel.des-open,
    .EPG-layout .channel.des-open .channel-name {
        height: 156px;
    }
    .EPG-layout .show-description .wrapper .show-time {
        padding-bottom: 0px;
    }
    .EPG-layout .show-description .wrapper .show-subtitle {
        -webkit-line-clamp: 1;
    }
}

@media screen and (max-width: 911px) {    
    a.btn-try, a.btn-cancel, .nav-view a, p.offer {
        font-size: 12px;
    }

    .detail-view-cls .detail-view .swiper-wrapper .titleHolder {
        height: 75px;
    }

    .detail-view-cls .detail-view.portrait-view .swiper-wrapper .titleHolder {
        height: 85px;
    }

    .detail-view-cls .detail-view .detailContent {
        padding: 50px 50px 0;
    }

    .detail-view-cls > div > h2 {
        left: -159px;
        top: 259px;
    }

    .detail-view-cls .detail-view .swiper-wrapper > .detail-swiper {
        margin-left: 130px;
    }

    .detail-view-cls .detailContent {
        width: calc(63vw - 132px);
    }
    .sponsorContent img{
        max-height: 190px;
    }
}

@media screen and (max-width: 767px), (max-height: 622px) {
   
    .subscribeScreen p {
        font-size: 12px;
    }
    .subscribeScreen-wrapper .logoHolder.smallImg {
        padding: 0px 0 10px;
    }

    .metering-counter, .video-title {
        width: 180px;
        height: 50px;
        line-height: 45px;
    }
    .video-title p {
        font-size: 14px;
    }
    .metering-counter span {
        font-size: 18px;
    }
    .sponsorContent img{
        max-height: 170px;
    }
}
@media screen and (max-width:580px) {
    .EPG-layout {
        --epg-name-width: 100px;
        --epg--current-time-width: 70px;
    }
    .EPG-layout .epg-header .time-head .time,
    .EPG-layout .epg-header .current-time {
        padding: 6px 8px;
        line-height: unset;
    }
    .EPG-layout .epg-header .current-time {
        font-size: 12px;
    }
    .EPG-layout .show-description .wrapper .show-title {
        -webkit-line-clamp: 2;
    }
    .EPG-layout .show-description .wrapper .show-summary {
        display: none;
    }
    .EPG-layout .channel .channel-name .channel-info {
        margin: auto;
    }
}

@media screen and (max-height:580px), (max-width: 768px){
    .video-inlineMenu .menu { 
        margin-top: 12px;
    }
    .video-inlineMenu .video-inlineMenuItem .title{
	    --video_inline_title_height: 50px;
    }
    .video-inlineMenu .video-inlineMenuItem .title p {
        font-size: 12px;
        line-height: 18px;
    }
    .sponsorContent img{
        max-height: 150px;
    }
}



