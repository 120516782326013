body {
    --detail_portait_width: 28vw;
	--video_inline_item_width: 18vw;
	--video_inline_item_height: calc(var(--video_inline_item_width) * 0.5625);
	--video_inline_title_height: 80px;
}

.ctv-container .detail-view .slick-slider .slick-slide {
    width: 38vw !important;
    padding-left: 13vw;
    padding-top: 1vw;
    padding-bottom: 2vw;
}

.ctv-container .detail-view .slick-slider .slick-slide .img-video {
    /* width: 100% !important; */
}

.ctv-container .detail-view.portrait-view .slick-slider .slick-slide {
    width: fit-content !important;
}

.ctv-container.two-by-three-poster .detail-view.portrait-view .slick-slider .slick-slide {
    width: 20vw !important;
    padding-bottom: 3vw;
}
.ctv-container.two-by-three-poster .detail-view.portrait-view .slick-slider .slick-slide .img-video {
    width: 100% !important;
}
.ctv-container.two-by-three-poster .detail-view.portrait-view .titleHolder {
    width: auto;
}
.ctv-container.two-by-three-poster .detail-view.portrait-view{
    --detail_portait_width: 24vw;
}

@media screen and (min-width: 1800px) {
    .ctv-container .detail-view .slick-slider .slick-slide {
        width: 35vw !important;
        padding-left: 11vw;
        padding-top: 1vw;
        padding-bottom: 8vw;
    }

    .ctv-container .jumbo-view .slick-slider .slick-slide {
        padding-top: 1vw;
        padding-bottom: 2vw;
    }

}

@media screen and (max-width: 1536px) {
    .ctv-container .detail-view .slick-slider .slick-slide {
        width: 38vw !important;
        padding-left: 13vw;
        padding-top: 1vw;
        padding-bottom: 5vw;
    }

    .ctv-container .jumbo-view .slick-slider .slick-slide {
        padding-top: 1vw;
        padding-bottom: 2vw;
    }
}

@media screen and (max-width: 1200px) {
    .ctv-container .detail-view .slick-slider .slick-slide {
        width: 44vw !important;
        padding-left: 15vw;
        padding-top: 1vw;
        padding-bottom: 5vw;
    }

    .ctv-container .jumbo-view .slick-slider .slick-slide {
        padding-top: 1vw;
        padding-bottom: 2vw;
    }
}

.ctv-container .detail-view-cls .detailContent {
    position: fixed;
    padding-top: 18vh;
}

.ctv-container .jumbo-view .slick-slider {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
}

.ctv-container .saved-jumboitems-wrapper .jumbo-view .slick-slider {
    margin-left: -5vw;
}



/* /// */
.slick_inline_menu {
    background: white;
    padding: 2em 0;    
    overflow-x: hidden;
    display: flex;
}


.slick_inline_menu .mar {
    margin: 0 15px;
}