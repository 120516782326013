
/* Css for Dropdown */
.preview-session-expired {
	display: flex;
	background: black;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
.preview-mode {
	flex-direction: row;
	display: flex;
	padding: 0px 30px;
	width: 100vw;
	height: 40px;
	background-color: yellow;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
}
.preview-mode .title {
	font-size: 16px;
	font-weight: bolder;
	color: #000;
}
.preview-mode .right-content { 
	flex-direction: row;
	padding-right: 10px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.preview-mode .right-content label {
	font-size: 14px;
	color: #000;
	margin-left: 20px;
}
  
.preview-mode .right-content .custom-select-container {
	display: inline-block;
	width: max-content;
	min-width: 100px;
	text-align: center;
	height: 100%;
	padding: 0;
	margin: 0;
	position: relative;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
  
.preview-mode .right-content .selected-text {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	padding: 0px 5px;
	font-size: 14px;
	height: 100%;
	color:#000;
	border-bottom: 1px solid yellow;
	margin-right: 30px;
}
  
.preview-mode .right-content .selected-text::after {
	content: "";
	position: absolute;
	right: 10px;
	top: 18px;
	font-size: 14px;
	border: 7px solid transparent;
	border-color: #000 transparent transparent transparent;
}
  
.preview-mode .right-content .selected-text.active::after {
	font-size: 16px;
	top: 10px;
	border-color: transparent transparent #000 transparent;
}
  
.preview-mode .right-content ul {
	margin: 0;
	padding: 0;
	text-align: center;
}
  
.preview-mode .right-content .select-options {
	z-index: 1000;
	position: absolute;
	width: 100%;
	font-size: 16px;
}
  
.preview-mode .right-content li {
	z-index: 1000;
	list-style-type: none;
	color: #000;
	padding: 6px 20px;
	background: yellow;
	border-bottom: 1px solid yellowgreen;
	cursor: pointer;
}
  
.preview-mode .right-content li:hover {
	background-color: yellowgreen;
	color: #ffffff;
}

.web-preview  .preview-mode + #tvod-overlay:has(.tvod-interstitial.section-interstitial), .web-preview  .preview-mode + #tvod-overlay:has(.video-loader) {
	top: calc(var(--nav-height) - 1px + var(--preview-banner-height)) !important;
}

@media screen and (max-width: 1536px){
	.web-route-container.web-preview .tvod-interstitial .description .tvod-summary span {
		-webkit-line-clamp: 7;
	}
}