@media (min-width:1401px){
    /* Global CSS */
    .logoHolder{padding:100px 0 80px; max-height:100px;}
    .logoHolder img{max-height:100px;}
    .logoHolder.smallImg, .logoHolder.smallImg img{max-height:150px;}
    .jumbo-view .img-video, .detail-view .img-video{border-radius:16px 16px 0 0;}
    .jumbo-view .img-thumb, .jumbo-view .video-thumb, .detail-view .img-thumb, .detail-view .video-thumb{border-radius:15px 15px 0 0;}


    /*Nav Bar */
    .nav-view{height:140px;}


    /*Banner */
    .banner .swiper-container{height:350px;}


    /* Grid View */
    .grid-view .swiper-container{padding:22px 70px 0;height:440px;}
    .grid-view h2{margin:0; padding:50px 70px 0;}
    /* .grid-view .titleHolder p{height:75px;} */
    /* .grid-view .titleHolder p{font-size:16px;} */
    .grid-view .titleHolder_not_used {
      display: flex;
      justify-content: space-evenly;
      margin-top: 10px;
      align-items: center
    }
    .nothumbBox.grid-view .swiper-container{height:400px;}


    /* Portrait Grid View */
    .grid-view.portrait-view .titleHolder p{height:115px;}
    .grid-view.portrait-view .swiper-container{height:640px;}
    .nothumbBox.grid-view.portrait-view .swiper-container{height:580px;}


    /* Jumbo View */
    .jumbo-view .swiper-container{height:565px;}
    .jumbo-view .img-video{height:400px;}
    .jumbo-view .swiper-slide{border-radius:9px 9px 9px 9px;}
    .jumbo-view .titleHolder{height:115px;}
    .jumbo-view .titleHolder p{max-height:80px;}
    .jumbo-view .titleHolder p{font-size:23px;}
    .jumbo-view h2 {width:350px; transform: rotate(270deg) translate(-200px, -125px);
        -webkit-transform: rotate(270deg) translate(-200px, -125px);
        -moz-transform: rotate(270deg) translate(-200px, -125px);
        -ms-transform: rotate(270deg) translate(-200px, -125px);
        -o-transform: rotate(270deg) translate(-200px, -125px);
    }

    /* Portrait Jumbo View */
    .jumbo-view.portrait-view .img-video{max-width:525px; height:400px;}
    .jumbo-view.portrait-view .titleHolder{height:140px;}
    .jumbo-view.portrait-view .titleHolder p{max-height:115px; padding:0 30px 0 20px;}
    .jumbo-view.portrait-view .swiper-container{height:590px;}


    /* Detail View */
    .detail-view .swiper-container{padding-top:45px;}
    .detail-view .swiper-slide{margin-left:180px; max-width:700px; border-radius:16px 16px 16px 16px;}
    .detail-view .titleHolder{border-radius:0 0 15px 15px; height:140px;}
    .detail-view .titleHolder p{max-height:80px; padding:0 20px;}
    .detail-view .titleHolder p{font-size:23px;}
    .detail-view .saveIcon a{padding:0 20px;}
    .detail-view .saveIcon{bottom:100px; width:calc(100% - 1000px);}
    .detail-view .img-video{max-width:700px;}
    .detail-view .icon-share, .detail-view .icon-star, .detail-view .icon, .detail-view .icon-starfill{
        border-top:30px solid #e5e5e5; border-bottom:30px solid #e5e5e5;
        border-left:40px solid #e5e5e5; border-right:40px solid #e5e5e5;
    }
    .detail-view h2 {width:465px; transform: rotate(270deg) translate(-255px, -108px);
        -webkit-transform: rotate(270deg) translate(-255px, -108px);
        -moz-transform: rotate(270deg) translate(-255px, -108px);
        -ms-transform: rotate(270deg) translate(-255px, -108px);
        -o-transform: rotate(270deg) translate(-255px, -108px);
    }
    .detailContent{width:calc(100% - 1000px); padding-top:80px;}
    .summaryHolder p{max-height:130px;}

    /* Portrait Detail View */
    .detail-view.portrait-view .titleHolder{width:475px; height:140px;}
    .detail-view.portrait-view .img-video{width:475px; height:545px;}
    .detail-view.portrait-view .swiper-slide{max-width:475px;}
    .detail-view.portrait-view .titleHolder p{max-height:115px;}


    /* Account LogIn */
    .logInScreen p{margin:-10px 0 80px 0;}
    .btn-fbIcon, .btn-email{margin-bottom:45px;}
    .btn-email{min-width:460px; padding:45px 70px;}
    .btn-fbIcon{min-width:600px; padding:30px 0;}
    .btn-fbIcon img {width:95px; height:95px; background-size:95px;}
    .btnBox a, span.btnForgot a, .btnBox button {padding:15px 35px;}
    .btnBox a, .btnBox button{margin:100px 20px 0 0;}
    span.btnForgot a{min-width:210px; margin:40px 20px 0;}
    button.continue-btn{min-width:250px;}
    a.bck-btn {min-width:120px;}
    button.login-btn {min-width:180px;}
    .logInScreen input{min-width:500px;}
    /* input[type="password"] {margin-left:395px;} */


    /* Settings */
    .logIn-container{height: calc(100% - 140px);}
    .settings-logoHolder img{max-height:100px;}
    .settings-logoHolder.smallImg img{max-height:150px;}
    .SettingScreen a{width:700px;}


    /* Sponsor Screen */
    .sponsorContent img {max-height:250px;}

    /*Subscription Screen */
    a.btn-trial {min-width:430px; margin:25px 0;}
    .subscription .logoHolder{padding:65px 0 40px;}


    /*Invalid Screen */
    a.btn-dismiss{min-width:500px; padding:12px 40px;}


    /*Unlock feed*/
    .unlockScreen{height:calc(100% - 140px);}


    /*MyQueue Save Screen (Play all)*/
    .play a {min-width:450px;}


    /*Search Screen*/
    .search-box input {width:600px; height:70px; padding-left:80px; border-radius:8px; background-size:32px; background-position:32px;}
    .searchContent{width:400px; margin:25px;}
    .searchContent .titleHolder{width:400px;}
    .searchContent .titleHolder p{height:75px; margin:30px 0 20px 0;}
    .search-row {padding-left:15px;}


    /* Full Screen Video */
    .prevThumbnail, .prevThumbnail img{width:400px;}
    .prevThumbnail img{height:225px;}


    /* CTA */
    .grid .ctaHolder{padding:5px 0 0 65px;}
    .detail .ctaHolder{padding:0 0 75px 0;}
    .jumbo .ctaHolder{padding:10px 0 0 150px;}


    /*Reset password*/
    .reContent a{min-width:380px; padding:8px 35px; margin:10px 0;}
}
