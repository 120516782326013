@font-face {
  font-family: 'San-Francisco-Light';
  src: url('../fonts/SFDisplay-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/SFDisplay-Light.eot?#iefix') format('embedded-truetype'), /* IE6-IE8 */
  url('../fonts/SFDisplay-Light.ttf') format('truetype'); font-weight: normal;
}
@font-face {
  font-family: 'San-Francisco-Regular';
  src: url('../fonts/SFDisplay-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/SFDisplay-Regular.eot?#iefix') format('embedded-truetype'), /* IE6-IE8 */
  url('../fonts/SFDisplay-Regular.ttf') format('truetype'); font-weight: normal;
}
@font-face {
  font-family: 'San-Francisco-Medium';
  src: url('../fonts/SFDisplay-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/SFDisplay-Medium.eot?#iefix') format('embedded-truetype'), /* IE6-IE8 */
  url('../fonts/SFDisplay-Medium.ttf') format('truetype'); font-weight: normal;
}
@font-face {
  font-family: 'San-Francisco-Semibold';
  src: url('../fonts/SFDisplay-Semibold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/SFDisplay-Semibold.eot?#iefix') format('embedded-truetype'), /* IE6-IE8 */
  url('../fonts/SFDisplay-Semibold.ttf') format('truetype'); font-weight: normal;
}
@font-face {
  font-family: 'San-Francisco-Bold';
  src: url('../fonts/SFDisplay-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/SFDisplay-Bold.eot?#iefix') format('embedded-truetype'), /* IE6-IE8 */
  url('../fonts/SFDisplay-Bold.ttf') format('truetype'); font-weight: normal;
}
@font-face {
  font-family: 'San-Francisco-Thin';
  src: url('../fonts/SFDisplay-Thin.eot'); /* IE9 Compat Modes */
  src: url('../fonts/SFDisplay-Thin.eot?#iefix') format('embedded-truetype'), /* IE6-IE8 */
  url('../fonts/SFDisplay-Thin.ttf') format('truetype'); font-weight: normal;
}
@font-face {
  font-family: 'San-Francisco-Text-Light';
  src: url('../fonts/SFText-Light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/SFText-Light.eot?#iefix') format('embedded-truetype'), /* IE6-IE8 */
  url('../fonts/SFText-Light.ttf') format('truetype'); font-weight: normal;
}
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?p9q8bj');
  src:  url('../fonts/icomoon.eot?p9q8bj#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?p9q8bj') format('truetype'),
    url('../fonts/icomoon.woff?p9q8bj') format('woff'),
    url('../fonts/icomoon.svg?p9q8bj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'small-caps';
  src: url('../fonts/small-caps.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
  font-variant: small-caps;
}
@font-face {
  font-family: 'monospaceSerif';
  src: url('../fonts/CourierPrime-Regular.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'monospaceSansSerif';
  src: url('../fonts/PTMono-Regular.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proportionalSerif';
  src: url('../fonts/Roboto-Regular.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'casual';
  src: url('../fonts/Handlee-Regular.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'script';
  src: url('../fonts/DancingScript-VariableFont_wght.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proportionalSansSerif';
  src: url('../fonts/PTSansCaption-Regular.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}