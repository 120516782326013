.more-section {
  height: calc(100vh - var(--ctv_nav_height));
}

.more-section .more-section-wrapper {
  display: flex;
  /* grid-template-columns: 64% 36%; */
  height: 100%;
  overflow: hidden;
}

.more-section .more-section-wrapper .cc-div {
  margin: 0;
}

.more-section .more-section-wrapper .left-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 64%;
}

.more-section .more-section-wrapper .left-wrapper img {
  width: 300px;
}

.more-section .more-section-wrapper .right-wrapper {
  padding: 40px 10px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.more-section .more-section-wrapper .item {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px;
  cursor: pointer;
  outline: none;
}

.more-section .more-section-wrapper .item > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.more-section .more-section-wrapper .item img {
  width: 60px;
}

.more-section .more-section-wrapper .item.header {
  font-size: 28px;
  justify-content: start;
  text-transform: uppercase;
}
.more-section .more-section-wrapper .item.header img {
  padding-right: 20px;
}
.more-section .ctv-link {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: black;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.more-section .more-section-wrapper .item:not(.header):hover {
  border-radius:7px;
  transform:scale(1.04);
  cursor:pointer;
  background-color:#acacaa;
}
.more-section .more-section-wrapper .item[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.web-route-container .more-section {
  height: calc(100vh - var(--nav-height));
}
.web-route-container .more-section .more-section-wrapper .item > span{
  font-size: 24px;
}
.web-route-container .more-section .more-section-wrapper .item.header  {
  font-size: 20px;
}
.web-route-container .more-section .more-section-wrapper .item img {
  width: 40px;
}

.web-route-container-mobile .more-section .more-section-wrapper {
  grid-template-columns: 100%;
  padding-top: 40px;
  box-sizing: border-box;
}
.web-route-container-mobile .more-section .more-section-wrapper .item {
  font-size: 14px;
}
.web-route-container-mobile .more-section .more-section-wrapper .item.header  {
  font-size: 12px;
}
.web-route-container-mobile .more-section .more-section-wrapper .item img {
  width: 24px;
}