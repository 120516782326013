.player-setting-modal {
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.player-setting-modal .player-setting-modal-content {
    background-color: rgb(53, 53, 53);
    width: 33%;
    position: relative;
}

.player-setting-modal .select-container {
    width: 100%;
    margin-right: 5%;
}

.player-setting-modal .content-modal-container {
    position: relative;
}

.player-setting-modal .content-modal-container button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.player-setting-modal .content-modal-container button:focus {
    outline: none;
    box-shadow: 0 0 5px #ccc;
}

.player-setting-modal .content-modal-container button span {
    margin-left: 10px;
}

.player-setting-modal .content-modal-container .options-container {
    left: 0;
    width: 90%;
    padding: 0 5%;
    z-index: 102;
}


.player-setting-modal .content-modal-container .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 2.3vh;
}

.player-setting-modal .content-modal-container .option.focusable:focus, .player-setting-modal .content-modal-container .option:hover {
    outline: none;
    border: none;
    box-shadow: none !important;
    background-color: rgb(128, 128, 128);
    border-radius: 5px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.player-setting-modal .content-modal-container {
    animation: fadeIn 1s;
}

.player-setting-modal .content-modal-container .header {
    width: 86%;
    padding: 7%;
    margin-bottom: 2%;
    font-size: 4vh;
}

.player-setting-modal .content-modal-container .header::after {
    content: "";
    position: absolute;
    margin-top: 5%;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgb(96, 96, 96);
}

.player-setting-modal .back-btn-div {
    position: absolute;
    top: 3.5%;
    right: 3.5%;
    z-index: 102;
    background-color: rgb(53, 53, 53);
    float: left;
    width: 7vh;
    height: 7vh;
    border-radius: 50%;
    background-color: rgb(53, 53, 53);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.player-setting-modal .back-btn-div.focusable:focus {
    outline: none;
    border: none;
    box-shadow: none !important;
    background-color: rgb(128, 128, 128);
}

.player-setting-modal .back-btn-div img {
    filter: brightness(0) invert(1);
    transform: rotate(180deg);
}

.player-setting-modal .content-modal-container .content-modal-option-select{
    display: flex;
    margin: 1%;
    align-items: center;
    justify-content: flex-end;
}

.player-setting-modal .content-modal-container .content-modal-option-select span{
    left: 3px;
}