/*font-family*/
body, input, select, textarea, .btnBox button{font-family:'San-Francisco-Regular', '.SFUIDisplay';}
.btn-fbIcon, .btn-email, p.subscribe, .unlockScreen p, .subscribeScreen p, .centerContent h3, .invalidContent h3, .smartContent, .invalidContent p, .grid-view h2, .grid-view .titleHolder p, .summaryHolder p, a.btn-trial, .searchContent .titleHolder p{font-family:'San-Francisco-Light', '.SFUIDisplay';}
.detail-view h2, .jumbo-view h2{font-family:'San-Francisco-Bold', '.SFUIDisplay'; font-weight:normal;}
a.btn-trial{font-family:'San-Francisco-Medium', '.SFUIDisplay';}
p.offer{font-family:'San-Francisco-Regular', '.SFUIDisplay' !important;}
p.large, .smartContent .heading{font-family: 'San-Francisco-Thin', '.SFUIDisplay';}
p.small, p.medium, .iconSet span i{font-family: 'San-Francisco-Text-Light', '.SFUIText-Light';}
.smartDetail h4, .view, .summaryHolder h4 {font-family:'San-Francisco-Semibold', '.SFUIDisplay'; font-weight:normal;}


/*Site font-size*/
p.small, .iconSet span i{font-size:22px;}
body, .prevThumbnail p, .search-box input{font-size:28px;}
.subscribeScreen p, .centerContent p, .invalidContent p .reContent p{font-size:30px;}
a.btn-try, a.btn-cancel, .nav-view a, p.offer{font-size:32px;}
.summaryHolder h4, .grid-view h2, span.btnForgot a, .btnBox a, .btnBox button{font-size:35px;}
.logInScreen p, .sponsorContent p{font-size:36px;}
.reContent a, a.btn-dismiss, .logInScreen input, .SettingScreen a, li span.left, li span.right, p.medium{font-size:38px;}
.btn-email, .centerContent h3, .invalidContent h3, .invalidContent.noItem p{font-size:40px;}
p.large{font-size:47px;}
.jumbo-view h2, .detail-view h2{font-size:50px;}
.icon-starfill, .icon-star, .icon-share, .icon-tick, span.icon-hollow_arrow{font-size:60px;}
.detail-view .icon-share, .detail-view .icon-star, .detail-view .icon, .detail-view .icon-starfill {font-size:70px;}


/* Global CSS */
body::-webkit-scrollbar {display:none;}
html, body, .content-box, .swiper-container, .videoBg, .gradientBg, .imageBg, .poweredby-screen, .sponsorContent, .colorBg, .invalidScreen, .detail-view, .poweredby-screen img, .img-banner, .slider-box.detail-view, .rePassword, .videoFull, .mediaOverlay, #overlay, .overlay, .imageFull, .imageFull img, .image-view, .video-js{width:100%; height:100%;}
body {margin:0; padding:0;}
.vMid, .slider-box.nav-view, .nav-view .swiper-slide, .settings-logoHolder, .poweredby-screen, .sponsorContent, .invalidScreen, .unlockScreen, .play, .search-box, .detail-view .saveIcon, .rePassword, .smartContent{
    display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;
    -webkit-box-pack: center; -ms-flex-pack: center; -webkit-justify-content: center; -webkit-box-align: center; -ms-flex-align: center; -webkit-align-items: center;
    align-items: center; justify-content: center;
}
[data-reactroot], .settings-logoHolder, .SettingScreen, .hiddenNav .logIn-container, .img-thumb, .portrait-view .video-thumb, .detailContent{height:100%;}
.img-thumb, .video-thumb, video, .detail-view .titleHolder p, .unlockScreen, .summaryHolder, .detail .ctaHolder, .centerContent, .acctContent, .smartContent{width:100%;}
.videoBg, .titleHolder p, .prevThumbnail p, .detail-view h2, .jumbo-view h2, .detail, .account, .settings, .img-video, .slider-box.nav-view, .subscription, .image-scroll, .disableScroll, .summaryHolder h4, .summaryHolder p{overflow:hidden;}
.invalidContent{width: 70%;}


.colorBg{z-index:-30;}
.imageBg{z-index:-20;}
.videoBg{z-index:-12;}
.gradientBg{z-index:-10;}
.img-thumb, .logoHolder, .detailContent{z-index:10;}
.video-thumb{z-index:20;}
.content-box, .remSpot{z-index:40;}
.videoFull, .mediaOverlay span, .prevThumbnail, .imageFull, .image-view span, .videoImg span, span.icon-hollow_arrow{z-index:50;}
.mediaOverlay{z-index:70;}
.slider-box.nav-view{z-index:100;}
#overlay, .overlay{z-index:200;}
.centerContent, .crossBtn, .acctContent, .image-view span{z-index:250;}
.cta .slider-box.nav-view{z-index:270;}

.videoBg, .imageBg, .colorBg, .gradientBg, .detail-view .saveIcon, .mediaOverlay, #overlay, .overlay{position:fixed;}
.img-video, .search-box, .logoHolder, .slider-box.nav-view, .mediaOverlay span, .iconSet span, .bye, span.icon-hollow_arrow{position:relative;}
.video-thumb, .prevThumbnail, .sponsorContent p, .detail-view h2, .jumbo-view h2, .detailContent, .img-thumb, .imageFull, .remSpot, .backBtn, .videoFull video, .crossBtn, .centerContent, .invalidContent, .acctContent, .loader, .image-view span, .videoImg span, .iconSet span i, span.happy, span.scary, .image-view, a.crossBtn, .settings-logoHolder, .video-js{position:absolute;}

.videoBg video {height:auto;}
.imageBg {background-position: center center; background-size:cover; background-repeat:no-repeat;}
.gradientBg {
    background: rgba(164,179,87,1);
    background: -moz-linear-gradient(-45deg, rgba(164,179,87,1) 0%, rgba(12,57,135,1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(164,179,87,1)), color-stop(100%, rgba(12,57,135,1)));
    background: -webkit-linear-gradient(-45deg, rgba(164,179,87,1) 0%, rgba(12,57,135,1) 100%);
    background: -o-linear-gradient(-45deg, rgba(164,179,87,1) 0%, rgba(12,57,135,1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(164,179,87,1) 0%, rgba(12,57,135,1) 100%);
    background: linear-gradient(135deg, rgba(164,179,87,1) 0%, rgba(12,57,135,1) 100%);
}

.userLoginCntnt .bckgrnds .gradientBg {
	backdrop-filter: blur(8px);
}
.video-thumb, .gradientBg, .sponsorContent p{top:0; left:0; right:0;}
.gradientBg, .imageFull, .videoFull video {bottom:0;}
.videoBg, .imageBg, .colorBg, .gradientBg, .mediaOverlay{top:0; left:0;}
.image-view span, .videoImg span, .image-view, .settings-logoHolder{top:0;}

.logoHolder{margin:0 auto; right:0; left:0;}
.backBtn{text-indent:-999999; left:0px; top:0px;}
.titleHolder p{white-space:normal;}
p.offer, p.subscribe, .unlockScreen p, .subscribeScreen p{margin:0; letter-spacing:1px;}
a.btn-trial, a.btn-dismiss, a.btn-try, a.btn-cancel, a.btn-unlock, .play a{letter-spacing:0.9px;}
.summaryHolder, a.thumbLink .img-video video.video-thumb, .detail-view .titleHolder a, .detail .logoHolder, .detail .banner, .icon-share, .noNav .nav-view, .mediaOverlay{display:none;}

a{text-decoration:none; cursor:pointer;}
a.thumbLink {margin:0 !important; outline:0;}
a.thumbLink.thumbHover:hover .img-video .img-thumb, a.thumbLink.hoverElmnt .img-video .img-thumb{opacity:1;}
a.thumbLink.thumbHover:hover .img-video .video-thumb, a.thumbLink.hoverElmnt .img-video .video-thumb, .img-thumb, .SettingScreen li, .detail .summaryHolder, .search-row, .showFeVideo, .showVideo, .showImage, .showGallery, .searchContent .thumbLink, .grid-view .thumbLink, img.bye, .titleHolder span, .saveIcon span{display:block;}
.detail-view h2, .jumbo-view h2{
    padding:0; white-space:nowrap; margin:0; text-transform:uppercase;
    left:initial; top:initial; text-align:right; text-overflow: ellipsis;
}
.detail-view .titleHolder, .jumbo-view .titleHolder{white-space:normal;display:flex;}
.detail-view .titleHolder p, .jumbo-view .titleHolder p{justify-content:center; align-self:center; vertical-align:middle;}
.btn-fbIcon, .btn-email, .btnBox a, .btnBox button, span.btnForgot a, a.btn-try, a.btn-cancel, a.btn-trial, a.btn-dismiss, a.btn-unlock, .play a, .SettingScreen a, .ctaHolder a, .reContent a{border-radius:8px;}
a.btn-dismiss, a.btn-trial, a.btn-try:hover, a.btn-cancel:hover, .reContent a:hover, a.btn-try.btnHover, a.btn-cancel.btnHover, .reContent a.btnHover {box-shadow:0 10px 20px 0 rgba(0,0,0,0.4)}
.btn-fbIcon:hover, .btn-email:hover, .btnBox a:hover, .btnBox button:hover, .btnForgot a:hover, .btn-fbIcon.btnHover, .btn-email.btnHover, .btnBox a.btnHover, .btnBox button.btnHover, .btnForgot a.btnHover, .btn-cta:hover, .btn-cta.btnHover, .reContent a:hover, .reContent a.btnHover{-webkit-transform:scale(1.1); -ms-transform:scale(1.1); transform:scale(1.1);}
.btnBox a, span.btnForgot a, .btnBox button, .btn-fbIcon, .btn-email, .logInScreen input, .grid-view .thumbLink, /*.jumbo-view .swiper-slide,*/ .icon-share, .icon-star, .icon-tick, .searchContent, .icon-starfill, .SettingScreen a, .play a, a.btn-trial, .reContent a, .btn-cta, .searchContent .thumbLink, .titleHolder a, img.icon, .titleHolder a img.icon{transition:all 0.2s ease-in-out;}
.logInScreen, .btn-fbIcon, .btn-email, .btnBox a, .btnBox button, span.btnForgot a, a.btn-try, a.btn-cancel, a.btn-trial, a.btn-dismiss, a.btn-unlock, .play a, .logInScreen input, .searchContent, .subscribeScreen, .invalidScreen, .logoHolder, .sponsorContent p, .grid-view .titleHolder, .grid-view .titleHolder p, .grid-view a, .detail-view .titleHolder p, .detail-view .saveIcon, .searchContent a, .rePassword, .reContent, .centerContent, .invalidContent, .acctContent, .iePopUp{text-align:center;}
.grid-view .swiper-slide, .jumbo-view .swiper-slide, span.fb-icon, .SettingScreen a, .btn-fbIcon, .btn-email, .btnBox a, .btnBox button, span.btnForgot a, a.btn-try, a.btn-cancel, a.btn-trial, a.btn-dismiss, .reContent a, a.btn-unlock, .play a, li span.left, li span.right, .searchContent, /*.icon-share, .icon-star, .icon-tick, .icon-starfill,*/ .grid-view .titleHolder a, .detail-view .saveIcon a,.remSpot, .mediaOverlay span, .btn-cta, .searchContent a, .bannerDetail, .bannerDetail span, .close, .iconSet span, .bye, .scary, .happy, a.crossBtn:after, a.crossBtn:before, a.crossBtn{display:inline-block;}

.showGallery .swiper-slide{background-size:cover; background-position:center center;}
a.btn-trial:hover, .play a:hover, a.btn-trial.btnHover, .play a.btnHover{-webkit-transform: scale(1.06); -ms-transform: scale(1.06); transform:scale(1.06);}

.img-thumb, .videoFull video, div embed, div iframe, div object, div video{object-fit:cover;}
.videoFull video, div embed, div iframe, div object, div video{object-position:top;}
.video-thumb{height:auto;}
.remSpot{height:4px; bottom:0; left:0;}
.portrait-view .video-thumb{width:auto; margin-left:-200px;}
.blurnav .mediaOverlay span{top:100px;}
.centerContent, .invalidContent, .loader{top:50%; left:50%; transform:translate(-50%,-50%);  -ms-transform:translate(-50%,-50%);}
.acctContent{top:54%; left:50%; transform:translate(-50%,-50%);  -ms-transform:translate(-50%,-50%);}
.btn-cta:hover, .btn-cta.btnHover{-webkit-box-shadow:0px 7px 20px -2px rgba(0,0,0,0.75); -moz-box-shadow:0px 7px 20px -2px rgba(0,0,0,0.75); box-shadow:0px 7px 30px -4px rgba(0,0,0,0.75);}
.titleHolder p{word-wrap:break-word; word-break:break-word; overflow-wrap:break-word;}
.titleHolder a:hover span, .saveIcon a:hover span, .titleHolder a.iconHover span, .saveIcon a.iconhover span{transform:scale(1.2); -webkit-transform:scale(1.2); -moz-transform:scale(1.2); -ms-transform:scale(1.2); -o-transform:scale(1.2);}

.titleHolder a img.icon {width: 45px; height: 45px;background-size: 45px 45px}
.grid-view .titleHolder a img.icon {margin-top: -10px;margin-left: 50px;}

img.icon:hover, .tick-icon:hover{
    text-shadow: 0px 4px 5px rgba(72, 72, 72, 0.5);  -webkit-backface-visibility: hidden; backface-visibility: hidden;
    transform: scale(1.2); -webkit-transform: scale(1.2); -moz-transform: scale(1.2);
    -webkit-transform-style: preserve-3d; -webkit-font-smoothing: antialiased;
    -webkit-transform: perspective(1px) scale(1.2);transform: perspective(1px) scale(1.2);
}
.vjs-big-play-button{display: none !important;}
.jumbo-view.portrait-view .titleHolder p, .detail-view.portrait-view .titleHolder p, .grid-view.portrait-view .titleHolder p{display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;}
.searchContent .titleHolder p, .jumbo-view .titleHolder p, .detail-view .titleHolder p, .grid-view .titleHolder p, p.prevText, .summaryHolder h4, .summaryHolder p{display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}
@-moz-document url-prefix() {.jumbo-view.portrait-view .titleHolder p, .detail-view.portrait-view .titleHolder p, .grid-view.portrait-view .titleHolder p, .jumbo-view .titleHolder p, .detail-view .titleHolder p, .grid-view .titleHolder p, p.prevText{display:block;}}
.subscribeScreen span:last-child a.btn-trial{margin:12px 0 50px 0;}
.img-video{height:0; padding-bottom:56.6%;}
.portrait-view .img-video{padding-bottom:133%;}
.two-by-three-poster .portrait-view .img-video{padding-bottom:150%;}

/* Site Colors */
.jumbo-view .titleHolder, .detail-view .titleHolder, a.btn-dismiss, a.btn-trial, a.btn-try:hover, a.btn-try.btnHover, a.btn-cancel:hover, a.btn-cancel.btnHover .logInScreen input, .play a, .jumbo-view .icon-starfill, .jumbo-view .icon-star, .jumbo-view .icon-share, .jumbo-view .icon-tick, .detail-view .icon-star, .detail-view .icon, .detail-view .icon-starfill, .detail-view .icon-share{color:#000;}
body, .nav-view a:hover, .nav-view a.active, .btn-fbIcon, .btn-email, .sponsorContent p, p.offer, p.subscribe, a.btn-try, a.btn-cancel, .unlockScreen p, .btnBox a, span.btnForgot a, .btnBox button, .prevThumbnail p, .SettingScreen li.subhead, .SettingScreen a, .reContent a, .btn-email a, .icon-starfill, .icon-star, .icon-share, .icon-tick, .smartContent .heading{color:#fff;}
.slider-box.nav-view{background-color:#7a7a7a;}
.btn-email{background-color:#7de4fc;}
.btn-fbIcon {background-color:#3b5998;}
a.btn-dismiss, a.btn-trial, a.btn-try:hover, a.btn-try.btnHover, a.btn-cancel:hover, a.btn-cancel.btnHover, a.btn-unlock, .logInScreen input:hover, .logInScreen input.btnHover, .btnBox a:hover, .btnBox a.btnHover, .btnBox button:hover, .btnBox button.btnHover, .btnForgot a:hover, .btnForgot a.btnHover, .play a, .ctaHolder a, .img-video, .jumbo-view .titleHolder, .detail-view .titleHolder, a.crossBtn:after, a.crossBtn:before{background-color:#fff;}
.colorBg, .prevThumbnail, .smartContent{background-color:#000;}
.btnBox a:hover, .btnForgot a:hover, .btnBox button:hover, .btnBox a.btnHover, .btnForgot a.btnHover, .btnBox button.btnHover{color:#000 !important;}
.logInScreen input{background-color: rgba(255, 255, 255, 0.35);}
a.btn-try, a.btn-cancel{background-color:#767676;}
a.btn-unlock{color:#68bf57}
.btnBox a, span.btnForgot a, .btnBox button{background-color:#929292;}
button.continue-btn{background-color:#f24e2f;}
::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder{color:#a6aaa9;}
.search-box input {background:rgba(225, 225, 225, 0.8);}
.themeColor .prevThumbnail, .themeColor .img-video, .themeColor .remSpot{background-color:#0f0;}
.themeColor .icon-star, .themeColor .icon-share, .themeColor .icon-starfill, .themeColor .icon-tick{color:#0f0;}
.nav-view a{color:#dcdcdc;}
.ctaHolder a{color:#51A7F9;}
.remSpot{background-color:#d61bbf;}
.SettingScreen a:hover, .SettingScreen a.btnHover{background-color:#acacaa;}
.transparent .slider-box.nav-view{opacity:0.7;}
.blurNav .slider-box.nav-view{background-color:rgba(105, 105, 105, 0.2);}
a.btn-reset{background-color:#d9573b !important;}
.reContent a{background-color:rgba(255, 255, 255, 0.5);}
p.small{color:#777777;}
p.medium{color:#f61f98;}
p.large{color:#1f1f1f;}
.smartDetail h4, .smartDetail p{color:#333333;}
.smartHeader{background-color:#f0f0f0;}
.view{color:#0042ff;}
.smartContent, .iconSet span i{color:#999999;}
.icon-hollow_arrow:before{color:#646464;}
#overlay, .overlay{background-color:rgba(0,0,0,0.85);}

/*Nav View */
.nav-view{margin:0 auto; display:block;}
.nav-view a {text-decoration:none;}
.nav-view .swiper-slide {width:auto; text-align:center; padding:0 15px;}
.nav-view .swiper-container{
    display:block; margin:0 auto; -moz-transition-duration: 0.3s; -webkit-transition-duration: 0.3s; -o-transition-duration: 0.3s; transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in; -webkit-transition-timing-function: ease-in; -o-transition-timing-function: ease-in; transition-timing-function: ease-in;
}
.hiddenNav .nav-view .swiper-container{
    height:0; -webkit-transition-duration:0.3s; -o-transition-duration:0.3s; transition-duration:0.3s;
    -moz-transition-timing-function:cubic-bezier(0, 1, 0.5, 1); -webkit-transition-timing-function:cubic-bezier(0, 1, 0.5, 1); -o-transition-timing-function:cubic-bezier(0, 1, 0.5, 1); transition-timing-function:cubic-bezier(0, 0, 0.5, 1);
}


/* Banner */
.banner .swiper-slide {display:inline-block; border-radius:25px;}
.banner .swiper-slide:hover, .banner .slideHover{transform:scale(1.03); -webkit-transform:scale(1.03); -moz-transform:scale(1.03); -ms-transform:scale(1.03); -o-transform:scale(1.03);}
.banner .swiper-container{padding:20px 0;}
.img-banner{border-radius:25px;}


/* Grid View */
.grid-view .swiper-container{box-sizing:border-box;}
.grid-view .titleHolder p{margin:20px 0 20px 0;}
.grid-view .thumbLink:hover, .grid-view .thumbLink.slideHover{transform:scale(1.07); -webkit-transform:scale(1.07); -moz-transform:scale(1.07); -ms-transform:scale(1.07); -o-transform:scale(1.07); -webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden; backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.1); }
.grid-view .thumbLink{-webkit-transform: translateZ(0);}


/* Portrait Grid View */
.grid-view .slideHover{transform:scale(1.06); -webkit-transform:scale(1.06); -moz-transform:scale(1.06); -ms-transform:scale(1.06); -o-transform:scale(1.06); -webkit-backface-visibility: hidden;-moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.06);}
.grid-view.portrait-view .titleHolder p{margin:20px 0px 10px 0;}


/* Jumbo View */
.jumbo-view .swiper-container{padding:25px 100px 20px; box-sizing:border-box;}
.jumbo-view .swiper-slide:hover, .jumbo-view .slideHover{transform:scale(1.06); -webkit-transform:scale(1.06); -moz-transform:scale(1.06); -ms-transform:scale(1.06); -o-transform:scale(1.06); -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden; -ms-backface-visibility: hidden;backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.06);}
.jumbo-view .titleHolder{border-radius:0 0 10px 10px;}
.jumbo-view .titleHolder p{padding:0 30px 0 15px; float:left; flex:auto; margin:0;}
.jumbo-view .titleHolder a{align-self:center; margin:0 15px 0 5px;}
.jumbo-view .icon-share, .jumbo-view .icon-star, .jumbo-view .icon-starfill{vertical-align:middle;}
.jumbo-view .titleHolder{box-shadow:0px 3px 16px -5px rgba(101,101,101,0.9);}
.jumbo-view .img-video{box-shadow:-1px 0 34px -8px rgba(101, 101, 101, 0.9); padding-bottom:0;}
.jumbo-view .video-thumb{min-height:100%; object-fit:cover;}


/* Portrait Jumbo View */
.jumbo-view.portrait-view .titleHolder p{padding:0 30px 0 20px;}
.jumbo-view.portrait-view .img-video{height:352px;}


/* Detail View*/
.detail-view .swiper-container{padding-bottom:250px; box-sizing:border-box;}
.detail-view .swiper-slide{height:auto; transition:all 0.2s ease-in-out; box-shadow:3px 4px 25px 1px rgba(0, 0, 0, 0.3); -webkit-box-shadow:3px 4px 25px 1px rgba(0, 0, 0, 0.3); -moz-box-shadow:3px 4px 25px 1px rgba(0, 0, 0, 0.3);}
.detail-view .swiper-slide:hover, .detail-view .slideHover{transform:scale(1.06); -webkit-transform:scale(1.06); -moz-transform:scale(1.06); -ms-transform:scale(1.06); -o-transform:scale(1.06); -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden; -ms-backface-visibility: hidden;backface-visibility: hidden; -webkit-transform-style: preserve-3d;-webkit-font-smoothing: antialiased; transform: perspective(1px) scale(1.06);}
.detail-view .titleHolder p{margin:0;}
.detail-view .saveIcon{right:0; padding-right:40px;}
.detail-view .saveIcon a{cursor:pointer;}
.detail-view .img-thumb{background-size:cover;}
.detail-view .icon-share, .detail-view .icon-star, .detail-view .icon, .detail-view .icon-starfill, .detail-view .tick-icon{border-radius:8px; vertical-align:middle; background-color:#e5e5e5;}
.summaryHolder p, .summaryHolder h4{margin:0; padding-right:50px;}
.summaryHolder h4{max-height:90px;}
p.summaryDescription{padding-top:30px !important;}
p.summaryDescription{max-height: 245px !important;}
.detailContent{top:0; right:0;}
.detail-view .icon-share:hover, .detail-view .icon-star:hover, .detail-view .icon:hover, .detail-view .icon-starfill:hover, .detail-view .icon-share.iconHover, .detail-view .icon-star.iconHover, .detail-view .icon-starfill.iconHover{-webkit-box-shadow: 0px 0px 24px 2px rgba(0,0,0,0.75); -moz-box-shadow: 0px 0px 24px 2px rgba(0,0,0,0.75); box-shadow: 0px 0px 20px 3px rgba(33, 33, 33, 0.5);}


/* Portrait Detail View */
.detail-view.portrait-view .swiper-container{padding-bottom:240px;}
.detail-view.portrait-view .img-video{padding-bottom:0;}


/* Account LogIn */
.btn-fbIcon{border:none; outline:none;}
.btnBox button{border:transparent;}
.logInScreen input{padding:12px; border:none;}
.logInScreen input:hover, .logInScreen input.btnHover, .searchContent .thumbLink:hover, .searchContent .thumbLink.slideHover{-webkit-transform:scale(1.07); -ms-transform:scale(1.07); transform:scale(1.07);}


/* Settings */
.settings-logoHolder{float:left; width:64%;}
.settings-logoHolder img {margin:0 auto;}
.SettingScreen{float:right; width:36%; margin-right:80px;}
.SettingScreen a{padding:5px 20px; margin:20px 0;}
.SettingScreen a:hover, .SettingScreen a.btnHover{border-radius:7px; transform:scale(1.04); cursor:pointer;}


/* Sponsor Screen */
.sponsorContent p {text-shadow:2px 2px 20px rgba(0,0,0,0.5)}


/* Image View */
.swiper-button-next, .swiper-button-prev {width:60px !important; height:60px !important; background-size:60px 60px !important; opacity:.35;}
.swiper-button-next{background-image: url('../../src/images/icons/arrow-next.png') !important;}
.swiper-button-prev{background-image: url('../../src/images/icons/arrow-prev.png') !important;}
.swiper-button-next:hover, .swiper-button-prev:hover{opacity:0.75;}


/*Subscription Screen */
a.btn-trial {padding:18px 25px;}
.subscribeScreen p{padding:0;}
p.offer{padding-bottom:30px;}


/*Invalid Screen */
.centerContent h3, .centerContent p, .invalidContent h3, .invalidContent p{margin:0; letter-spacing:0.8px;}
.centerContent p, .invalidContent p{padding:15px 0 30px;}


/*Invalid Email, Product, Payment Transaction and Verification Failed*/
a.btn-try, a.btn-cancel {min-width:365px; padding:8px 40px; margin-top:-20px; margin-bottom:35px;}
a.btn-try:hover, a.btn-cancel:hover, a.btn-try.btnHover, a.btn-cancel.btnHover{-webkit-transform: scale(1.08); -ms-transform: scale(1.08); transform:scale(1.08); -o-transform:scale(1.08);}


/*Unlock feed*/
a.btn-unlock {min-width:100px; padding:0 20px;}


/*MyQueue Save Screen (Play all)*/
.play a {padding:30px 36px; box-shadow:0 10px 20px 0 rgba(0,0,0,0.4);}
.play{margin:60px 0 50px;}


/*Search Screen*/
.search-box input {border:none; float:left;outline:none; background-image:url('../../src/images/icons/search-icon.png'); background-repeat:no-repeat;}
.search-box {padding:60px 0 45px;}
.searchContent{width:400px; margin:25px;}
.searchContent .titleHolder{margin:0 auto;}
.search-row {margin:0 auto; width:94%;}
input[type=search]{ -moz-appearance: none; -webkit-appearance: none; appearance: none;}


/*Full screen video */
.prevThumbnail{top:45px; right:50px;}
.prevThumbnail p{margin:8px 10px;}
.prevThumbnail img{object-fit:cover;}
.blurNav .prevThumbnail, .blurNav .videoImg span{top:100px;}
.prevText {margin:10px 0; height:45px;}


/* CTA */
.ctaHolder a{padding:0 10px; border-radius:4px; margin:0 5px;}
p.box-cta{letter-spacing:0.5px; margin:0 !important;}


/* IE Browser */
.iePopUp{margin-top:100px;}
p.medium{margin-top:-15px;}
img.bye {margin: auto;}
.iconSet span, .bye, .scary, .happy{background-image:url('../../src/images/ie-browser-sprite.png'); background-size:967px 513px;}
.iconSet {margin-top: 45px;}
.iconSet span{width:90px; height:80px; margin:-10px 25px;}
.bye {width:105px; height:80px; margin-top: -20px; }
.iconSet span i{font-style:normal; left:0; top:85px; width:90px;}
span.safari{background-position: -192px -387px;}
span.firefox{background-position: -335px -384px;}
span.chrome{background-position: -475px -384px;}
span.bye{background-position: -37px -400px;}
span.scary{right:20px; top:0px; background-position: -760px -250px; width:200px; height:220px;}
span.happy{left:10px; bottom:0; background-position: -600px -370px; width:120px; height:100px;}


/* Smart Banner */
.smartHeader{padding:14px 20px; height:107px; box-sizing:border-box;}
@-moz-document url-prefix() {.header{padding: 10px 20px;}}
.close{vertical-align:middle; height:82px;}
.rating{background-image: url('../../src/images/rating5.png');background-repeat:repeat-x; width:100px; height:24px;}
.view{float:right; line-height:86px;}
.appLogo img{width:78px; border-radius:12px;}
.appLogo{margin-left:6px;}
.smartDetail{margin-left:10px;}
.smartDetail h4, .smartDetail p, .smartContent p{margin:0;}
.smartContent{
    background: -moz-linear-gradient(-45deg, #000 0%, #525252 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, #000), color-stop(100%, #525252));
    background: -webkit-linear-gradient(-45deg, #000 0%, #525252 100%);
    background: -o-linear-gradient(-45deg, #000 0%, #525252 100%);
    background: -ms-linear-gradient(-45deg, #000 0%, #525252 100%);
    background: linear-gradient(135deg, #000 0%, #525252 100%);
    height: -moz-calc(100% - 107px);
    height: -webkit-calc(100% - 107px);
    height: calc(100% - 107px);
}
.smartContent .heading{padding-bottom:5px;}
.smartContent span{align-self:center;}


/* Close Btn */
a.crossBtn:after, a.crossBtn:before{content: ''; width:20px; height:2px;}
a.crossBtn:after{transform: translate(-10px,20px) rotate(45deg); -webkit-transform: translate(-10px,20px) rotate(45deg); -moz-transform: translate(-10px,20px) rotate(45deg);}
a.crossBtn:before{transform: translate(10px,20px) rotate(-45deg); -webkit-transform: translate(10px,20px) rotate(-45deg); -moz-transform: translate(10px,20px) rotate(-45deg);}
a.crossBtn{width:40px; height:40px; top:10px; left:10px;}


/* Icon Fonts */
[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-hollow_arrow:before{content: "\e901";}
.icon-fb:before{content: "\e900";}
.icon-next_arrow:before{content: "\e902";}
.icon-previous_arrow:before{content: "\e903";}
.icon-search:before{content: "\e904";}
.icon-share:before{content: "\e905";}
.icon-star:before{content: "\e906";}
.icon-starfill:before{content: "\e907";}
.icon-tick:before{content: "\e908";}
.icon-starfill:hover, .icon-star:hover, .icon:hover, .icon-share:hover, .icon-tick:hover, .icon-starfill.iconHover, .icon-star.iconHover, .icon-share.iconHover, .icon-tick.iconHover{text-shadow:0px 4px 5px rgba(72, 72, 72, 0.5);}
span.icon-hollow_arrow{left:10px; top:10px;}
span.icon-hollow_arrow:after {
    content: ''; position: absolute; width: 42px; height: 32px; background: #fff; top: 13px; left: 9px; z-index: -1;
}

.video-thumb{height:100%;}

.imageBg img{width: 100%; height: 100%; object-fit: cover;}

#fullscreen-grid .grid-view .thumbLink {
    margin-top: 0.25em !important;
    margin-bottom: 0.85em !important;
}