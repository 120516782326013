
.parental-lock-container {
  height: calc(100vh - var(--ctv_nav_height));
}

.parental-lock-container .parental-lock-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.parental-lock-container .parental-lock-wrapper .cc-div {
  margin: 0;
}

.parental-lock-container .parental-lock-wrapper .left-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 64%;
}

.parental-lock-container .parental-lock-wrapper .left-wrapper img {
  width: 300px;
}

.parental-lock-container .parental-lock-wrapper .right-wrapper {
  padding: 40px 20px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

.parental-lock-container .parental-lock-wrapper .item {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 32px;
  cursor: pointer;
  outline: none;
}
.parental-lock-container .parental-lock-wrapper .item:hover,
.parental-lock-container .parental-lock-wrapper .item:focus {
  border-radius:7px;
  transform:scale(1.04);
  cursor:pointer;
  background-color:#acacaa;
}
.parental-lock-container .parental-lock-wrapper .item[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.code-input-container {
  width: 600px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
  min-height: 80vh;
}
.code-input-container > div {
  padding-bottom: 20px;
}
.code-input-container .keyboard-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  background: #262424;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 80%;
}

.code-input-container .keyboard-wrapper .key-button {
  width: calc(25% - 22px);
  height: 60px;
  background: #4b4a4a;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.code-input-container .keyboard-wrapper .key-button:last-child {
  width: 100%;
}
.code-input-container .msg {
  font-size: 60%;
  color: #abaaa9;
  padding: 5px;
}
.code-input-container .error {
  font-size: 60%;
  color: red;
  padding: 5px;
}

.parental-lock .lock-overlay {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin: auto;
  width: 700px;
  min-height: 80vh;
  text-align: center;
  font-size: 32px;
}
.parental-lock .lock-overlay .title {
  padding: 40px 20px;
  font-weight: bold;
}

.parental-lock .lock-overlay .des {
  padding: 10px;
  font-size: 70%;
}
.parental-lock .lock-overlay .buttons {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  font-size: 70%;
}
.parental-lock .lock-overlay .buttons .bttn {
  background: #4b4a4a;
  border-radius: 10px;
  padding: 10px 20px;
  min-width: 120px;
}
.parental-lock .lock-overlay .qr-wrapper {
  padding: 20px 0;
}

.web-route-container .parental-lock-container .parental-lock-wrapper .item,
.web-route-container .code-input-container,
.web-route-container .parental-lock .lock-overlay {
  font-size: 28px;
}

@media screen and (max-width: 1600px) {
  .web-route-container .parental-lock-container .parental-lock-wrapper .item,
  .web-route-container .code-input-container,
  .web-route-container .parental-lock .lock-overlay {
    font-size: 26px;
  }
}

.web-route-container-mobile .parental-lock-container .parental-lock-wrapper .item {
  font-size: 20px;
}
.web-route-container-mobile .parental-lock-container .parental-lock-wrapper .right-wrapper {
  padding-top: 60px;
}
.web-route-container-mobile .code-input-container,
.web-route-container-mobile .parental-lock .lock-overlay {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 20px;
  overflow: scroll;
  min-height: 90vh;
}
.web-route-container-mobile .code-input-container {
  height: calc(100vh - 70px);
}
.web-route-container-mobile .tvod-interstitial .code-input-container {
  height: 100%
}
.web-route-container-mobile .parental-lock .lock-overlay .title {
  padding: 10px;
}
.web-route-container-mobile .code-input-container .keyboard-wrapper {
  width: 80%;
  padding: 10px;
}
.web-route-container-mobile .code-input-container .keyboard-wrapper .key-button {
  margin: 5px;
  height: 40px;
  width: calc(25% - 11px);
}
.web-route-container-mobile .code-input-container .keyboard-wrapper .key-button:last-child {
  width: 100%;
}
.web-route-container-mobile .parental-lock .lock-overlay .buttons {
  width: 85%;
}
.web-route-container-mobile .parental-lock .lock-overlay .buttons .bttn {
  min-width: 50px;
  font-size: 50%;
}