.vizio-account-container {
  display: flex;
  flex-direction: column;
  background: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vizio-account-container .title {
  text-align: center;
  font-weight: bold;
  color: white;
}

.vizio-account-container .subtitle {
  text-align: center;
  color: white;
}

.vizio-account-container .btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
}

.vizio-account-container .focusable:focus {
  transform: scale(1.3);
}

.vizio-account-container button {
  border: none;
  outline: none;
  background: rgb(24, 38, 53) none repeat scroll 0% 0%;
  color: #fff;
  cursor: pointer;
  z-index: 0;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 3vw;
  padding: 10px;
  width: 20vw
}