  /*SpotLight Theme CSS*/

  .h-screen {
    height: 100vh;
  }

  .bottom-0 {
    bottom: 0;
  }

  .fixed {
    position: fixed;
  }

  .z-10 {
    z-index: 10;
  }

  .z-0 {
    z-index: 0;
  }

  .h-full {
    width: 100%;
  }

  .left-0 {
    left: 0;
  }

  .top-0 {
      top: 0;
  }

  .right-0 {
      right: 0;
  }

  .absolute {
    position: absolute;
  }

  #previewSection {
    position: fixed;
    height: 50%;
    width: 100%;
    margin: auto;
    top: 0px;
    z-index: 0;
  }

  #previewDescription {
    width: calc(40% - 25px);
    left: 61px;
    height: 200px;
    position:relative;
    top: 70px;
    z-index:10;
  }
  .simulator-route-container .section-content #previewDescription,
  .simulator-route-container.ctv-container #previewDescription {
    top: 40px;
  }

  #previewVideo,
  #previewImage {
    position: absolute;
    right:0px;
    top:0;
    width: 60vw; 
    height: 100%;
    z-index: 5;
  }

  .previewTitle{
    width: 100%;
    font-size: 30px;
  }

  .previewSubtitle{
    white-space: nowrap;
    width: 100%;
    text-align: left;
    font-size: 19px;
    margin-top: 10px;
    height: 22px;
    color: grey;
    text-overflow: ellipsis;
  }

  #previewSummary{
    width: 100%;
    font-size: 19px;
    margin-top: 10px;
    overflow: hidden;
  }


  #spotlight-preview_html5_api, #spotlight-preview{
    position: absolute;
    top:0px;
    object-fit: cover;
    height: 100%;
    right: 0px;
  }

  .web-route-container #spotlight-preview_html5_api, .web-route-container #spotlight-preview{
    object-fit: contain;
  }

  .ctv-container #previewVideo .theoplayer-container video{
    object-fit: cover;
    object-position: center;
  }

  .ctv-container #previewVideo{
    overflow: hidden;
  }

  #image-preview{
    height: 100%;
    width:100%;
    object-fit: contain;
    object-position: center;
    right: 0px;
  }

  #previewShadow{
    position: absolute;
    top:0px;
    right: 0px;
    width: 101%;
    height: 101%;
    box-shadow: inset 63px -35px 62px 10px rgba(0,0,0,0.999);
  }


  #spotlight-grid { 
    width: 100%;
    height: 50%;
    bottom: 0;
    z-index: 15;
    position: fixed;
    top: 50%;
  }

  .spotlight * * div.img-video{
    border-radius: 10px;
    border-color: black;
    /* this applies to both spotlight & full screen */
  }
  #spotlight-grid .spotlight * * div.img-video{
	  padding-bottom: 55.25%;
  }
  #spotlight-grid .spotlight.square-view div.img-video {
	  padding-bottom: 100%;
  }

  #previewVideo > .vjs-ended > .vjs-poster {
    display: block;
  }

  .spotlight.grid-view .swiper-container{height:250px;}

  .previewSubtitle > span {
    margin-right: 25px;
  }
  .simulator-route-container .section-content #previewSection {
    top: calc(var(--ctv_nav_height) - 1px) !important;
    height: 40%;
  }
  .simulator-route-container .section-content #spotlight-grid {
    top: calc(40% + var(--ctv_nav_height));
    height: calc(60% - var(--ctv_nav_height));
  }
  .simulator-route-container .section-content #previewVideo,
  .simulator-route-container .section-content #previewImage {
    width: 40%;
  }
  .simulator-route-container .section-content  #previewDescription {
    width: 55%;
  }
  .web-route-container #previewVideo,
  .web-route-container #previewImage {
    top:calc(-1 * var(--nav-height));
    height: 400px;
  }
  .web-preview #previewVideo,
  .web-preview #previewImage {
    top: calc(-1 * var(--nav-height) + var(--preview-banner-height));
  }
  .web-route-container #spotlight-grid {
    height: calc(100% - 400px);
    top: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .web-route-container #spotlight-grid .spotlight.grid-view > .swiper-container {
    margin-bottom: 30px;
  }
  #previewVideo img,
  #previewImage img {
    object-position: top;
  }