.detail-view-cls{height: 100%;width: 100%;}
.with-modal{height: 100%;}
.image-view{height: 100%;}
.detail .user-access .logoHolder{
    display: block;
}
.searchContent.hovered{-webkit-transform:scale(1.1); -ms-transform:scale(1.1); transform:scale(1.1);}
.detail-view .swiper-container{padding-bottom: 330px;}
.imageFull, .imageFull img{height: 100%; width: 100%;}
.fake-video-ad{height: 100%}
.termsOfUse{
  position: absolute;
  bottom: 5px;
  left: 0px;
  right: 0px
}

.termsOfUse li{
  display: inline-block;
  padding: 0px 100px
}

.termsOfUse li a{
  color: #fff;
  font-size: 22px;
}
.mail-icon {
  background-image: url("https://s3.amazonaws.com/resources.magappzine.com/assets/core/staging/mail_icon.png");
  background-repeat: no-repeat;
  background-size: 30px 25px;
  width: 30px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px 0px;
  margin-right: 12px;
}

.sponsorContent {
  height: 100vh;
}