/* Switch Slider */
.switch-slider-wrapper {
	position: relative;
	display: inline-block;
	width: 110px;
    height: 60px;
}
  
.switch-slider-wrapper input { 
	opacity: 0;
	width: 0;
	height: 0;
}
  
.switch-slider-wrapper .switch-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}
  
.switch-slider-wrapper .switch-slider:before {
	position: absolute;
	content: "";
	height: 50px;
	width: 50px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}
  
.switch-slider-wrapper input:checked + .switch-slider {
	background-color: #2196F3;
}
  
.switch-slider-wrapper input:focus + .switch-slider {
	box-shadow: 0 0 1px #2196F3;
}
  
.switch-slider-wrapper input:checked + .switch-slider:before {
	-webkit-transform: translateX(50px);
	-ms-transform: translateX(50px);
	transform: translateX(50px);
}
  
  /* Rounded sliders */
.switch-slider-wrapper .switch-slider.round {
	border-radius: 34px;
}
  
.switch-slider-wrapper .switch-slider.round:before {
	border-radius: 50%;
}

@media screen and (max-width: 1600px) {
	.data-privacy .switch-slider-wrapper {
        width: 100px;
        height: 53px;
	}
	.data-privacy .switch-slider-wrapper .switch-slider:before {
        height: 45px;
        width: 45px;
    }
    .data-privacy .switch-slider-wrapper input:checked + .switch-slider:before {
        -webkit-transform: translateX(45px);
        -ms-transform: translateX(45px);
        transform: translateX(45px);
    }
}
@media screen and (max-width: 1440px) {
	.data-privacy .switch-slider-wrapper {
        width: 90px;
        height: 48px;
	}
	.data-privacy .switch-slider-wrapper .switch-slider:before {
        height: 40px;
        width: 40px;
    }
    .data-privacy .switch-slider-wrapper input:checked + .switch-slider:before {
        -webkit-transform: translateX(40px);
        -ms-transform: translateX(40px);
        transform: translateX(40px);
    }
}