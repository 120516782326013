
.right-to-left .cc-div {
	padding-right: 0;
  margin-right: 0;
}
.web-route-container.right-to-left .SettingScreen {
	float: left;
}
.web-route-container.right-to-left .mobile-view .SettingScreen ul {
	padding-right: 0;
}
.ctv-container.right-to-left .SettingScreen ul {
	padding-right: 0;
}
.right-to-left #previewDescription {
	direction: rtl;
	right: 0;
	margin-left: calc(60% - 100px);
}
.right-to-left #previewDescription .previewSubtitle {
	text-align: right;
}
.right-to-left.web-route-container .jumbo-view h2 {
	transform: rotate(270deg) translate(-112px, calc(100vw - 160px));
	direction: rtl;
}
.right-to-left .tvod-interstitial .tvod-interstital-extras .extra-title {
	direction: rtl;
}
.right-to-left .series .description .details,
.right-to-left .series .season-list {
	direction: rtl;
}
.web-route-container.right-to-left .tvod-interstitial .description .item-play .arrow-right,
.right-to-left .series .description .item-play .arrow-right {
	padding-right: 10px;
}
.right-to-left .detail-view-cls .swiper-container-vertical>.swiper-wrapper {
	padding-right: 120px;
}
.web-route-container.right-to-left .detail-view-cls > div > h2 {
	transform: rotate(270deg) translate(-220px, calc(100vw - 160px));
	direction: rtl;
}
.web-route-container.right-to-left .detail-view-cls .detailContent {
	left: 0;
	direction: rtl;
}

.web-route-container-mobile.right-to-left .tvod-interstitial .tvod-interstital-extras .extra,
.web-route-container-mobile.right-to-left .video-grid-content h2,
.web-route-container-mobile.right-to-left .series .episode  {
	direction: rtl;
}
.web-route-container-mobile.right-to-left .series .episode .episode-des,
.web-route-container-mobile.right-to-left .tvod-interstitial .tvod-interstital-extras .extra .extra-des {
	padding-right: 10px;
}
.right-to-left .more-section-wrapper .right-wrapper {
	direction: rtl;
	padding-right: 30px !important;
}
.web-route-container-mobile.right-to-left .more-section-wrapper .right-wrapper {
	padding-right: 10px !important;
}
.web-route-container.right-to-left .tvod-interstitial .description-wrapper {
	direction: rtl;
	margin-left: 55%;
	padding-right: 30px;
}
.web-route-container-mobile.right-to-left .tvod-interstitial .description .description-wrapper {
	margin-left: 0px;
}
.web-route-container.right-to-left .tvod-interstitial .description .item-play > span {
	margin-right: 0;
	margin-left: 30px;
}
.web-route-container-mobile.right-to-left .tvod-interstitial .description .item-play > span {
	margin: 0;
}
.web-route-container.right-to-left #previewVideo,
.web-route-container.right-to-left #previewImage {
	left: 0;
}
.right-to-left .series .description .details {
	padding-right: var(--common-paddding-left);
	right: 0;
}
.right-to-left .series .image-wrapper {
	float: unset;
}
.right-to-left .settings-logoHolder {
	right: 0;
}
.right-to-left .tvod-interstitial .more-sub-wrapper {
	direction: rtl;
}
.right-to-left .tvod-interstitial .base #previewShadow {
	background-image: linear-gradient(to left, rgba(0,0,0,0.6), rgba(0,0,0,0.3), rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0));
}