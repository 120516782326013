

@media (max-width:1400px){
  /* Global CSS */
  .logoHolder{padding:50px 0 40px; max-height:65px;}
  .logoHolder img {max-height:65px;}
  .logoHolder.smallImg img, .logoHolder.smallImg{max-height:90px;}
  .btnBox a, .btnBox button, .btn-fbIcon, .btn-email, .logInScreen input, .detail-view .icon-star, .detail-view .icon, .detail-view .icon-share, .detail-view .icon-starfill, span.btnForgot a, .play a, a.btn-trial, li.settingScale:hover, li.settingScale.btnHover, .reContent a, a.btn-dismiss {border-radius:4px;}
  .backBtn{width:60px; height:60px;}
  .jumbo-view .img-video, .detail-view .img-video{border-radius:9px 9px 0 0;}
  .jumbo-view .img-thumb, .jumbo-view .video-thumb, .detail-view .img-thumb, .detail-view .video-thumb{border-radius:8px 8px 0 0;}


  /* Font Size */
  .bannerDetail p{font-size:13px;}
  .view, .smartDetail h4{font-size:16px;}
  .prevThumbnail p, p.small, .iconSet span i{font-size:16px;}
  .SettingScreen li.subhead, .search-box input{font-size:18px;}
  .smartContent span{font-size:19px;}
  .detail-view .titleHolder p, .searchContent .titleHolder p, .ctaHolder a, p.box-cta, .centerContent p, .invalidContent p{font-size:20px;}
  .nav-view a, .jumbo-view .titleHolder p, .btnBox a, .btnBox button, span.btnForgot a, .play a, .reContent p, .summaryHolder p{font-size:22px;}
  .searchContent .titleHolder p, .grid-view .titleHolder p{
    font-size:20px;
    text-align: left;
  }
  .nav-view a{
    font-size:20px;
  }
  .searchContent .titleHolder, .grid-view .titleHolder {
      display: flex;
      justify-content: space-evenly;
      align-items: center
    }
  .searchContent .titleHolder .title, .grid-view .titleHolder .title {
    float: left;
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    }
  li span.left, li span.right, .SettingScreen a, .sponsorContent p, .logInScreen input, a.btn-dismiss, a.btn-trial, .subscribeScreen p {font-size:24px;}
  .grid-view h2, .logInScreen p, .reContent a{font-size:26px;}
  .centerContent h3, .invalidContent h3, p.medium, .smartContent .heading, p.offer{font-size:28px;}
  .summaryHolder h4{font-size: 30px; letter-spacing:0.9px;}
  .jumbo-view h2, .detail-view h2, .btn-email{font-size:34px;}
  p.large{font-size:38px;}
  .icon-starfill, .icon-star, span.icon-share, span.icon-tick{font-size:45px;}
  .detail-view .icon-share, .detail-view .icon-star, .detail-view .icon, .detail-view .icon-starfill{font-size:50px;}


  /* Nav Bar */
  .nav-view{
    height:85px;
    position: fixed;
    width: 100%;
  }


  /* Banner */
  .banner .swiper-container{height:235px;}


  /* Grid View */
  .grid-view .swiper-container{padding:18px 60px 0 60px; height:300px;}
  .grid-view h2 {padding:0; margin:32px 60px 0;}
  .grid-view .titleHolder p{height:62px;}
  .nothumbBox.grid-view .swiper-container{height:290px;}


  /* Portrait Grid View */
  .grid-view.portrait-view .swiper-container{height:400px;}
  .nothumbBox.grid-view.portrait-view .swiper-container{height:350px;}


  /* Jumbo View */
  .jumbo-view .swiper-container{height:450px;}
  .jumbo-view .swiper-slide{border-radius:9px 9px 9px 9px;}
  .jumbo-view .img-video{height:300px;}
  .jumbo-view .titleHolder{height:100px;}
  .jumbo-view .titleHolder p{max-height:60px;}
  .jumbo-view .titleHolder p{font-size:23px;}
  .jumbo-view h2 {width:350px; transform: rotate(270deg) translate(-200px, -112px);
    -webkit-transform: rotate(270deg) translate(-200px, -112px);
    -moz-transform: rotate(270deg) translate(-200px, -112px);
    -ms-transform: rotate(270deg) translate(-200px, -112px);
    -o-transform: rotate(270deg) translate(-200px, -112px);
  }


  /* Portrait Jumbo View */
  .jumbo-view.portrait-view .titleHolder{height:110px;}
  .jumbo-view.portrait-view .titleHolder p{max-height:100px;}
  .jumbo-view.portrait-view .img-video{max-width:350px; height:352px;}
  .jumbo-view.portrait-view .swiper-container{height:510px;}


  /* Detail View */
  .detail-view .swiper-container{padding-top:35px;}
  .detail-view .swiper-slide{margin-left:120px; max-width:500px; border-radius:9px 9px 9px 9px;}
  .detail-view .titleHolder{border-radius:0 0 8px 8px; height:100px;}
  .detail-view .titleHolder p{max-height:60px; padding:0 10px;}
  .detail-view .titleHolder p{font-size:23px;}
  .detail-view .saveIcon a{padding:0 15px;}
  .detail-view .saveIcon{bottom:70px; width:calc(100% - 700px);}
  .detail-view .img-video{max-width:500px;}
  .detail-view .icon-share, .detail-view .icon-star, .detail-view .icon, .detail-view .icon-starfill{
    border-top:20px solid #e5e5e5; border-bottom:20px solid #e5e5e5;
    border-left:27px solid #e5e5e5; border-right:27px solid #e5e5e5;
  }
  .detail-view h2 {width:340px; transform: rotate(270deg) translate(-195px, -90px);
    -webkit-transform: rotate(270deg) translate(-195px, -90px);
    -moz-transform: rotate(270deg) translate(-195px, -90px);
    -ms-transform: rotate(270deg) translate(-195px, -90px);
    -o-transform: rotate(270deg) translate(-195px, -90px);
  }
  .detailContent{width:calc(100% - 700px); padding-top:45px;}
  .summaryHolder p{max-height:30px;padding-top: 10px;}


  /* Portrait Detail View */
  .detail-view.portrait-view .titleHolder{width:330px; height:105px;}
  .detail-view.portrait-view .img-video{width:330px; height:365px;}
  .detail-view.portrait-view .saveIcon, .portrait-view .detailContent{width:calc(100% - 650px);}
  .detail-view.portrait-view .swiper-slide{max-width:330px;}
  .detail-view.portrait-view .titleHolder p{height:85px;}


  /* Settings */
  .logIn-container{height: calc(100% - 85px);}
  .settings-logoHolder img{max-height:65px;}
  .settings-logoHolder.smallImg img{max-height:90px;}
  .SettingScreen a{width:430px;}


  /* Account LogIn */
  .btn-fbIcon {min-width:435px; padding:20px 15px}
  .btn-email{min-width:400px; padding:15px 15px;}
  .btn-fbIcon img{width:55px; height:50px; background-size:30px; vertical-align: middle; margin-right: 12px;}
  .btn-fbIcon, .btn-email{margin-bottom:33px;font-size: 34px;}
  .btnBox a, .btnBox button, span.btnForgot a{padding:10px;}
  span.btnForgot a{min-width:200px; margin:20px 20px 0;}
  .btnBox a, .btnBox button{margin:90px 12px 0;}
  button.continue-btn{min-width:200px;}
  .logInScreen p{margin:-10px 0 40px 0;}
  .logInScreen input{min-width:330px;}
  a.bck-btn {min-width:100px;}
  button.login-btn {min-width:150px;}
  /* input[type="password"] {margin-left:260px;} */


  /* Sponsor Screen */
  .sponsorContent img {max-height:180px;}


  /* Invalid screen */
  a.btn-dismiss{min-width:272px; padding:5px 40px;}


  /*Unlock feed*/
  .unlockScreen{height:calc(100% - 85px);}

  /*MyQueue Save Screen (Play all)*/
  .play a{min-width:300px;}


  /*Search Screen*/
  .search-box input {width:320px; height:40px; padding-left:45px; border-radius:5px; background-size:20px; background-position:15px;}
  .searchContent .titleHolder{width:360px;}
  .searchContent .titleHolder p{height:55px; margin:20px 0 10px 0;}
  .searchContent{width:360px; margin:15px;}
  .search-row{padding-left:30px;}


  /* Full Screen Video */
  .prevThumbnail img, .prevThumbnail{width:270px;}
  .prevThumbnail img{height:150px;}

  /*CTA*/
  .grid .ctaHolder{padding:5px 0 0 55px;margin-bottom: -20px;font-size: 26px;}
  .detail .ctaHolder{margin-bottom: -20px;font-size: 26px;}
  .jumbo .ctaHolder {padding:10px 0 0 100px;height: 70px;}


  /*Subscription Screen */
  .subscription .logoHolder{padding:65px 0 25px;}
  a.btn-trial {min-width:378px; margin:12px 0;}


  /*Reset Password*/
  .reContent a{min-width:250px; padding:8px 40px; margin:6px 0;}



  /*Smart Banner */
  @media (max-width:767px){
    .header{padding: 14px 10px;}
    @-moz-document url-prefix() {.header{padding: 10px 10px;}}
  }


  img[src=""]{
    content: "";
  }

  .detail-view .img-video, .jumbo-view .img-video{border-radius: 10px 10px 0 0;}
  .jumbo-view .swiper-slide {  border-radius: 10px 10px 10px 10px;}

  .lock-icon {
    display: inline-block;
    max-width: 25px;
    margin-bottom:-2px;
    padding-right:2px
  }
  .meteringOverScreen{
      height: 100%;
      width: 100%;
      opacity: 0.9;
      background-color: #808080
  }
  .metering-counter, .video-title{
    background-color: #040404;
    width: 270px;
    height: 75px;
    border-radius: 10px;
    text-align: center;
    line-height: 70px;
  }
  .video-title p{
    margin: 0px 10px !important;
    font-size: 22px;
  }

  .ellipsis p{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
